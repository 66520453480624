import { useDebouncedEffect, useEventListener } from "@react-hookz/web";
import { useCallback, useEffect, useState } from "react";

import deepEqual from "fast-deep-equal/es6";
import { useHistory } from "react-router";

const UPDATE_SETTINGS_DEBOUNCE_TIME_MS = 1500;

/**
 * React hooks that traces form values execute update whenever value changes
 */
export const useAutoUpdate = <T>(
  formFields: T,
  updateDataFn: (param: T) => void | Promise<void>
) => {
  const [lastSyncValues, setLastSyncValues] = useState(formFields);
  const [inSync, setInSync] = useState(true);
  const history = useHistory();

  useEventListener(window, "beforeunload", (e: BeforeUnloadEvent) => {
    const message = !inSync
      ? "You have unsaved changes, are you sure?"
      : undefined;

    if (message) {
      e.preventDefault();
      e.returnValue = message;
    }
    return message;
  });

  if (inSync && !deepEqual(lastSyncValues, formFields)) {
    setInSync(false);
  }

  const doSave = useCallback(async () => {
    await updateDataFn(formFields);
    setLastSyncValues(formFields);
    setInSync(true);
  }, [updateDataFn, formFields]);

  const doSaveIfNecessary = useCallback(() => {
    if (!formFields || deepEqual(lastSyncValues, formFields)) {
      return;
    }
    return doSave();
  }, [doSave, lastSyncValues, formFields]);

  useDebouncedEffect(
    doSaveIfNecessary,
    [doSaveIfNecessary, lastSyncValues, formFields],
    UPDATE_SETTINGS_DEBOUNCE_TIME_MS
  );

  useEffect(() => {
    return history.listen(doSaveIfNecessary);
  }, [history, doSaveIfNecessary]);
};

import { ToastContainer } from "@asantech/common/react/ToastContainer/ToastContainer";
import { Tooltip } from "components";
import { AppRouter } from "./AppRouter";
import { history } from "./history";

function App() {
  return (
    <>
      <ToastContainer
        hideProgressBar
        closeButton={false}
        position="bottom-center"
      />
      <Tooltip id="tooltip" />
      <AppRouter history={history} />
    </>
  );
}

export default App;

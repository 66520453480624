import client from "api/client";
import {
  cameraSchedule,
  cameraSettings,
  deviceGroupsUri,
  devicesUri,
  liveStream,
} from "api/endpoints";
import {
  CameraSchedule,
  CameraSettings,
  Device,
  DeviceGroup,
  LiveStreamRequestParams,
  LiveStreamResponse,
  PeriodByWeekDay,
} from "../../../backend/src/devices/types";

export async function getDevices() {
  const response = await client.get<Device[]>(devicesUri);
  return response.data;
}

export async function getDeviceGroups() {
  const response = await client.get<DeviceGroup[]>(deviceGroupsUri);
  return response.data;
}
export async function updateCameraSettings(settings: CameraSettings) {
  const response = await client.put<CameraSettings>(cameraSettings, {
    ...settings,
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  });
  return response.data;
}

export async function updateCameraSchedule(
  deviceId: string,
  activityPeriods: PeriodByWeekDay[]
) {
  const response = await client.put<CameraSchedule>(
    cameraSchedule(deviceId),
    activityPeriods
  );
  return response.data;
}

export async function getCamerasSettings() {
  const response = await client.get<CameraSettings[]>(`${cameraSettings}`);
  return response.data;
}

export async function getLiveStream(params: LiveStreamRequestParams) {
  const response = await client.post<LiveStreamResponse>(
    `${liveStream}`,
    params
  );
  return response.data;
}

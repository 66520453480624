import { usePrevious } from "@react-hookz/web";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Event } from "../../../../backend/src/events/types";
import { AlertsByDay, useCameras } from "../../store/Cameras.context";

const AMOUNT_RENDERED_ALWAYS = 10;

export const useFeedVirtualList = (alertsByDay: AlertsByDay) => {
  const { firstItemIndex, setIsFeedAtTop } = useCameras();
  const groups = useMemo(() => Object.keys(alertsByDay || {}), [alertsByDay]);
  const [openedGroups, setOpenedGroups] = useState<string[]>([]);
  const [selectedImages, setSelectedImages] = useState<Record<string, number>>(
    {}
  );
  const previousGroups = usePrevious(groups);

  const data = useMemo(
    () =>
      groups.reduce((prev, day) => {
        prev.push(day);
        if (openedGroups.includes(day)) {
          prev.push(...(alertsByDay[day] || []));
        }

        return prev;
      }, [] as (string | Event)[]),
    [alertsByDay, groups, openedGroups]
  );

  useEffect(() => {
    if (previousGroups?.length === 0 && groups.length > 0 && groups[0]) {
      setOpenedGroups([groups[0]]);
    }
  }, [groups, previousGroups?.length]);

  const toggleOpenGroup = useCallback(
    (groupName: string) => {
      setOpenedGroups((prevState) => {
        const isOpen = openedGroups.includes(groupName);
        return isOpen
          ? prevState.filter((x) => x !== groupName)
          : [...prevState, groupName];
      });
    },
    [openedGroups]
  );
  const totalCount = useMemo(() => data.length, [data.length]);
  return {
    data,
    openedGroups,
    toggleOpenGroup,
    selectedImages,
    setSelectedImages,
    virtuosoProps: {
      totalCount,
      firstItemIndex,
      initialItemCount: Math.min(totalCount, AMOUNT_RENDERED_ALWAYS),
      atTopStateChange: setIsFeedAtTop,
    },
  };
};

import { ReactComponent as ErrorIcon } from "assets/svgs/alert-error-icon.svg";
import styled from "styled-components";
import { ReactComponent as CloseIcon } from "../../../assets/svgs/close-icon.svg";
import { Button } from "../../../components";
import { Camera } from "../../../models/cameras";

interface Props {
  handleCloseBtnClick: () => void;
  cameraData: Camera;
  error?: Error;
}
export const CameraBarContent = (props: Props) => {
  const { cameraData, error, handleCloseBtnClick } = props;

  return (
    <>
      <CameraName>{cameraData.name}</CameraName>
      <Button
        data-testid="closeBtn"
        onClick={handleCloseBtnClick}
        withIcon
        variant="tertiary"
      >
        <CloseIcon height="20" />
      </Button>
      {error && (
        <StatusIcon>
          <ErrorIcon
            data-tooltip-id="tooltip"
            data-tooltip-content={error.message}
          />
        </StatusIcon>
      )}
    </>
  );
};

const CameraName = styled.p`
  font-size: 16px;
  font-weight: bold;
  color: var(--text-primary);
  padding-right: 16px;
`;

const StatusIcon = styled.div`
  line-height: 0;
  margin-left: auto;

  svg {
    width: 20px;
    height: 20px;
  }
`;

import { useCallback, useMemo } from "react";
import styled from "styled-components";
import { ScheduleCalendar } from "./ScheduleCalendar";
import {
  SCHEDULE_RESOLUTION_MINUTES,
  getCalendarPeriods,
} from "./calendarPeriods";
import { PeriodByWeekDay } from "./types";
import {
  getSplitPeriodsFnForCalendarResolution,
  mergeContiguousPeriods,
} from "./utils";

interface Props {
  onChange: (currentValue: PeriodByWeekDay[]) => void;
  value: PeriodByWeekDay[];
}

const calendarPeriods = getCalendarPeriods(SCHEDULE_RESOLUTION_MINUTES);
const split = getSplitPeriodsFnForCalendarResolution(calendarPeriods);

export function Schedule(props: Props) {
  const { onChange, value } = props;

  const prepareForPersist = useCallback(
    (periods: PeriodByWeekDay[]) => {
      const merged = mergeContiguousPeriods(periods);
      onChange(merged);
    },
    [onChange]
  );

  const periodsSplitted = useMemo(() => split(value), [value]);

  return (
    <ScheduleWrapper data-testid="scheduleWrapper">
      <ScheduleCalendar
        activePeriods={periodsSplitted}
        onChange={prepareForPersist}
        allPeriods={calendarPeriods}
      ></ScheduleCalendar>
    </ScheduleWrapper>
  );
}

const ScheduleWrapper = styled.div`
  max-height: 550px;
  overflow: scroll;
`;

import { ReactComponent as CloseIcon } from "assets/svgs/close-icon.svg";
import React, { useCallback } from "react";
import styled from "styled-components";
import { Button } from "../Button";

type FieldSize = "normal" | "large";

type FieldVariant =
  | "error"
  | "warning"
  | "info"
  | "secondaryDark"
  | "secondary";

interface Props {
  id: string;
  name?: string;
  variant?: FieldVariant;
  size?: FieldSize;
  disabled?: boolean;
  onRemove?: (id: string) => void;
}

const variantColors: Record<FieldVariant, string> = {
  info: "var(--info)",
  error: "var(--alert)",
  warning: "var(--warning)",
  secondaryDark: "#25272C",
  secondary: "var(--secondary)",
};

export const Tag = ({
  id,
  name,
  onRemove,
  variant = "secondary",
  size = "normal",
  disabled,
}: Props) => {
  const handleRemove = useCallback(
    () => onRemove && onRemove(id),
    [id, onRemove]
  );

  return (
    <Wrapper variant={variant} disabled={disabled}>
      <Name fieldSize={size}>{typeof name === "undefined" ? id : name}</Name>
      {onRemove && !disabled ? (
        <Button
          type="button"
          withIcon
          onClick={handleRemove}
          variant="transparent"
        >
          <CloseIcon />
        </Button>
      ) : (
        ""
      )}
    </Wrapper>
  );
};

export const Wrapper = styled.span<{
  variant: FieldVariant;
  disabled?: boolean;
}>`
  display: inline-block;
  background: ${({ variant }) => variantColors[variant]};
  border-radius: 4px;
  margin-right: 5px;

  &:last-child {
    margin-right: 0;
  }

  ${({ disabled }) => (disabled ? " opacity: 0.4;" : "")}
  ${({ disabled, variant }) =>
    disabled && variant === "secondary"
      ? "background: var(--secondary-variant)"
      : ""}

  button {
    margin-left: -12px;
  }
`;

const Name = styled.span<{ fieldSize: FieldSize }>`
  display: inline-block;
  padding: 9.5px 16px;

  &:first-letter {
    text-transform: uppercase;
  }

  ${({ fieldSize }) => (fieldSize === "large" ? "padding: 16px 16px;" : "")}
`;

import { useToggle } from "@react-hookz/web";
import { ReactComponent as LogoIcon } from "assets/svgs/6sense-logo.svg";
import { useAuth } from "common/hooks/use-auth";
import { useTokenKeepAlive } from "common/hooks/use-token-keep-alive";
import { Button, CurrentUser, DialogPopup } from "components";
import { HamburgerMenu } from "components/HamburgerMenu";
import React, { SyntheticEvent, useCallback } from "react";
import styled from "styled-components";

export const TopBar: React.FC = React.memo(() => {
  const { logout, userInfo } = useAuth();
  const [isMenuOpen, toggleMenu] = useToggle(false);
  const closeMenu = useCallback(() => toggleMenu(false), [toggleMenu]);
  const toggleNoPropagate = useCallback(
    (event: SyntheticEvent) => {
      event.stopPropagation();
      toggleMenu();
    },
    [toggleMenu]
  );

  useTokenKeepAlive();

  return (
    <Wrapper>
      <LeftContent isOpen={isMenuOpen} onClick={closeMenu}>
        <HamburgerMenuWrapper>
          <HamburgerMenu isOpen={isMenuOpen} toggle={toggleMenu} />
        </HamburgerMenuWrapper>
        <LogoIcon
          onClick={toggleNoPropagate}
          width={66}
          height={32}
          title={"6sense Innovations Oy"}
        />
      </LeftContent>
      <DialogPopup
        renderActivator={({ toggle }) => (
          <CurrentUserWrapper onClick={toggle}>
            <CurrentUser userDetails={userInfo} />
          </CurrentUserWrapper>
        )}
        renderContent={() => <Button onClick={logout}>Log out</Button>}
      />
    </Wrapper>
  );
});

TopBar.displayName = "TopBar";

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 56px;
  background: var(--secondary);
  padding: 8px 16px 8px 16px;
  box-shadow: 0px 3px 6px #00000029;
`;

const LeftContent = styled.div<{ isOpen: boolean }>`
  display: flex;
  align-items: center;

  svg {
    z-index: 1000;
    position: relative;
    cursor: ${({ isOpen }) => (isOpen ? "" : "pointer")};
  }
`;

const HamburgerMenuWrapper = styled.div`
  svg {
    height: 32px;
    vertical-align: middle;
  }
`;

const CurrentUserWrapper = styled.div`
  cursor: pointer;
  min-width: 140px;
  display: flex;
  justify-content: flex-end;
`;

import React from "react";

import styled from "styled-components";
import { LoginForm } from "views/login";

const currentYear = new Date().getFullYear();

export const LoginView = () => {
  return (
    <StyledLoginView>
      <LoginForm />
      <CopyrightInfo>© {currentYear} 6sense Innovations Oy</CopyrightInfo>
    </StyledLoginView>
  );
};

const StyledLoginView = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  background: var(--primary);
`;

const CopyrightInfo = styled.p`
  position: absolute;
  bottom: 32px;
  font-size: 12px;
  color: var(--text-primary);
`;

import React, { ReactNode, useCallback } from "react";

import { ReactComponent as ChevronDownIcon } from "assets/svgs/chevron-down-icon.svg";
import { useHeight } from "common/hooks";
import styled from "styled-components";

interface Props {
  title: string;
  collapsed?: boolean;
  onCollapseChange?: (collapsed: boolean) => void;
  children?: ReactNode;
}

export const Collapse: React.FC<Props> = ({
  title,
  children,
  collapsed = true,
  onCollapseChange,
}) => {
  const [heightRef, height] = useHeight();
  const styles = {
    opacity: collapsed ? 1 : 0,
    height: collapsed ? height : 0,
  };
  const clicked = useCallback(() => {
    onCollapseChange && onCollapseChange(!collapsed);
  }, [onCollapseChange, collapsed]);
  return (
    <Wrapper>
      <Head onClick={clicked}>
        <Title>{title}</Title>
        <IconWrapper collapsed={!collapsed}>
          <ChevronDownIcon />
        </IconWrapper>
      </Head>

      {children && !collapsed && (
        <Body style={{ ...styles, overflow: "hidden" }}>
          <ContentWrapper ref={heightRef}>{children}</ContentWrapper>
        </Body>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  background: var(--secondary);
`;

const Head = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 3px 6px #00000014;
  padding: 9.5px 16px;
  background: var(--tertiary);

  &:hover {
    cursor: pointer;
  }
`;

const Title = styled.span`
  padding-right: 8px;
  font-size: 12px;
  color: var(--text-primary);
  font-weight: bold;
`;

const Body = styled.div`
  transition: height 300ms ease-in-out;
  overflow: hidden;
`;

const ContentWrapper = styled.div`
  padding: 16px;
`;

const IconWrapper = styled.div<{ collapsed: boolean }>`
  & > svg {
    transform: ${({ collapsed }) =>
      collapsed ? "rotate(0)" : "rotate(90deg)"};
    transition: 0.2s transform ease-in-out;
  }
`;

export const subtractArray = (arr1: unknown[], arr2: unknown[]) => {
  return arr1.filter((n) => !arr2.includes(n));
};

export const noDuplicates = <T>(arr: T[]): T[] => {
  return Array.from(new Set(arr));
};

export const merge = <T>(arr: T[][]): T[] => ([] as T[]).concat(...arr);

export const keyBy = <T>(array: T[], key: keyof T) =>
  (array || []).reduce((prev, cur) => {
    const index = cur[key] + "";
    prev[index] = cur;
    return prev;
  }, {} as Record<string, T>);

export const pluck = <T>(array: T[], key: keyof T) => {
  return array.map((value) => value[key]);
};

import { useEffect, useState } from "react";

export const useSecondsAgo = (from: Date) => {
  const [, setNow] = useState(new Date().getTime());

  useEffect(() => {
    const timer = setInterval(() => {
      setNow(new Date().getTime()); // forces rerender every sec
    }, 1000);
    return () => clearInterval(timer);
  });

  return from
    ? Math.floor((new Date().getTime() - from.getTime()) / 1000)
    : undefined;
};

import { PeriodByWeekDay } from "../../../../backend/src/devices/types";
import { dayMinutesToLocalPeriodTime, MINUTES_IN_DAY } from "./utils";

export const SCHEDULE_RESOLUTION_MINUTES = 30;

export const calendarDays = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

export const getCalendarPeriods = (
  calendarResolution = SCHEDULE_RESOLUTION_MINUTES
): PeriodByWeekDay[] => {
  const result = [];

  for (
    let dayMinutes = 0;
    dayMinutes < MINUTES_IN_DAY;
    dayMinutes += calendarResolution
  ) {
    for (let dayOfWeek = 1; dayOfWeek < 8; dayOfWeek += 1) {
      const toMinutes = dayMinutes + calendarResolution;
      const [from, fromDayOfWeek] = dayMinutesToLocalPeriodTime(
        dayMinutes,
        dayOfWeek
      );
      const [to] = dayMinutesToLocalPeriodTime(toMinutes, dayOfWeek);

      result.push({
        from,
        to: to === "00:00:00" ? null : to,
        dayOfWeek: fromDayOfWeek === 7 ? 0 : fromDayOfWeek,
      });
    }
  }

  return result;
};

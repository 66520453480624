import { createSelectable, TSelectableItemProps } from "react-selectable-fast";
import styled, { css } from "styled-components";
import { PeriodByWeekDay } from "./types";

interface Props {
  cellPeriod: PeriodByWeekDay;
}

export interface SelectedCell {
  props: Props;
}

export const Cell = createSelectable<Props>(
  (props: TSelectableItemProps & Props) => {
    const { selectableRef, isSelected, isSelecting, cellPeriod } = props;
    return (
      <CellWrapper
        ref={selectableRef}
        selected={isSelected}
        selecting={isSelecting}
        fill={cellPeriod.fill}
        position={cellPeriod.position}
      />
    );
  }
);

const CellWrapper = styled.div<{
  selected?: boolean;
  selecting?: boolean;
  fill?: number;
  position?: "top" | "bottom";
}>`
  overflow: visible;
  position: relative;
  border-right: var(--border);
  border-bottom: var(--border);
  box-sizing: content-box;
  user-select: none;
  cursor: pointer;

  ${(props) =>
    props.selecting &&
    css`
      & {
        opacity: 0.5;
      }
    `}

  ${(props) =>
    (props.selected || props.selecting) &&
    css`
      &:after {
        content: "";
        position: absolute;
        ${props.position || "top"}: 0;
        left: 0;
        width: 100%;
        height: ${100 * (props.fill || 1)}%;
        background-color: var(--active);
        border-right: var(--border-active);
        border-bottom: var(--border-active);
        box-sizing: content-box;
      }
    `}
`;

import { backendRootUrl } from "../config";
import { getToken } from "@asantech/common/react/localStorage";

export const loginUri = "/auth/token";
export const keepAliveUrl = "/keep-alive";
export const devicesUri = "/devices";
export const cameraSettings = `${devicesUri}/settings`;
export const cameraSchedule = (deviceId: string) =>
  `${devicesUri}/schedule/${deviceId}`;
export const deviceGroupsUri = `${devicesUri}/groups`;
export const eventsWsUri = "/events/listen";
export const eventsUri = "/events/batch";
export const eventsImages = "/events/images";
export const liveStream = `${devicesUri}/livestream`;
export const cameraWs = "/cameraStream";
export const cameraStreamUrl = (token: string) =>
  `${backendRootUrl.replace(/^http/, "ws")}${cameraWs}?token=${token}`;
export const alertRules = "/alerts/rules";
export const appUrls = "/app-urls";
export const accounts = "/accounts";
export const getImageUrl = (imageId: string) =>
  `${backendRootUrl}${eventsImages}/${imageId}?token=${getToken()}`;

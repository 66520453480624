import { AreaEditor as AreaEditorCommon } from "@asantech/common/react/CamPreview";
import { useCameras } from "store/Cameras.context";

type Props = Omit<
  React.ComponentProps<typeof AreaEditorCommon>,
  "status" | "extraLayers"
> & { deviceId: string };

export const AreaEditor: React.FC<Props> = ({
  deviceId,
  children,
  ...props
}) => {
  const { detectionStatusByDevice } = useCameras();
  const { alertOn } = detectionStatusByDevice[deviceId] || {};

  return (
    <AreaEditorCommon {...props} alertOn={alertOn}>
      {children}
    </AreaEditorCommon>
  );
};

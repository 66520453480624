import React, { MouseEventHandler, ReactElement } from "react";

import { ReactComponent as ErrorIcon } from "assets/svgs/alert-error-icon.svg";
import { ReactComponent as InfoIcon } from "assets/svgs/alert-info-icon.svg";
import { ReactComponent as WarningIcon } from "assets/svgs/alert-warning-icon.svg";
import styled, { css, keyframes } from "styled-components";
import { Occurrences, OccurrencesProps } from "./Occurrences";

export type AlertVariant = "info" | "error" | "warning";

interface AlertBoxProps extends OccurrencesProps {
  variant: AlertVariant;
  title: string;
  time: string;
  description: string;
  isAlertOn: boolean;
  note?: string;
  handleAlertClick: MouseEventHandler;
  children?: Element | JSX.Element | ReactElement | string | false;
}

export const AlertBox: React.FC<AlertBoxProps> = React.memo(
  ({
    variant,
    isAlertOn,
    title,
    time,
    description,
    note,
    occurrencesCount,
    firstSeen,
    handleAlertClick,
    children,
  }) => {
    return (
      <Wrapper
        bgColor={alertBackgroundColor[variant]}
        blinkBgColor={alertBgBlinkColor[variant] || "var(--info-blink)"}
        animate={isAlertOn}
        data-testid={`alert-${title}`}
        onClick={handleAlertClick}
      >
        <ContentWrapper>
          <>
            <Head>
              <IconWrapper>
                <Icon variant={variant} />
              </IconWrapper>
              <Title>{title}</Title>
              <RightCorner>
                {time} <br />
                <Occurrences
                  occurrencesCount={occurrencesCount}
                  firstSeen={firstSeen}
                />
              </RightCorner>
            </Head>
            {description && <Description>{description}</Description>}
            {children}
            {note && (
              <Footer>
                <FooterText>{note}</FooterText>
              </Footer>
            )}
          </>
        </ContentWrapper>
      </Wrapper>
    );
  }
);

AlertBox.displayName = "Alert";

interface TinyAlertProps {
  variant: AlertVariant;
  title: string;
  onClick?: React.MouseEventHandler<HTMLElement>;
}

export const TinyAlert: React.FC<TinyAlertProps> = React.memo(
  ({ variant, title, onClick }) => {
    return (
      <TinyWrapper
        onClick={onClick}
        bgColor={alertBackgroundColor[variant] || "var(--info)"}
        blinkBgColor={alertBgBlinkColor[variant] || "var(--info-blink)"}
      >
        <IconWrapper>
          <Icon variant={variant} />
        </IconWrapper>
        <ContentWrapper>
          <Head>
            <Title>{title}</Title>
          </Head>
        </ContentWrapper>
      </TinyWrapper>
    );
  }
);

TinyAlert.displayName = "TinyAlert";

const Icon: React.FC<{ variant: AlertVariant }> = ({ variant }) => {
  switch (variant) {
    case "error":
      return <ErrorIcon />;

    case "info":
      return <InfoIcon />;

    case "warning":
      return <WarningIcon />;

    default:
      return <InfoIcon />;
  }
};

const alertBackgroundColor = {
  info: "var(--info)",
  error: "var(--alert)",
  warning: "var(--warning)",
};
const alertBgBlinkColor = {
  info: "var(--info-blink)",
  error: "var(--alert-blink)",
  warning: "var(--warning-blink)",
};

const blinkingKeyFrames = (bgColor: string, blinkBgColor: string) =>
  keyframes`
    0% {
      background: ${bgColor};
      color: var(--text-primary);
    }
    50% {
      background: ${blinkBgColor};
      color: var(--text-primary-blink);
    }
    100% {
      background: ${bgColor};
      color: var(--text-primary);
    }
  `;

const Wrapper = styled.div<{
  bgColor: string;
  blinkBgColor: string;
  animate?: boolean;
}>`
  display: flex;
  flex-direction: row;
  border-radius: 4px;
  padding: 10px;
  background: ${({ bgColor }) => bgColor};
  box-shadow: 0px 0px 6px #00000014;
  cursor: pointer;

  animation: ${(props) =>
    props.animate
      ? css`
          ${() =>
            blinkingKeyFrames(
              props.bgColor,
              props.blinkBgColor
            )} 0.8s linear infinite;
        `
      : css`
          animation: none;
          color: var(--text-primary);
        `};
`;

const IconWrapper = styled.div`
  padding-top: 2px;
`;

const ContentWrapper = styled.div`
  padding: 5px 0;
  width: 100%;
`;

const Head = styled.div`
  display: flex;
  justify-content: space-between;
  letter-spacing: 0px;
  padding: 0 0 10px 0;
`;

const Title = styled.span`
  margin-right: auto;
  margin-left: 10px;
  font-size: 14px;
  font-weight: bold;
  text-align: left;
`;

const RightCorner = styled.span`
  font-size: 12px;
  text-align: right;
`;

const Description = styled.p`
  text-align: left;
  padding-top: 8px;
`;

const Footer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 16px;
  font-size: 12px;
  letter-spacing: 0px;
`;

const FooterText = styled.span`
  text-align: left;
`;

const TinyWrapper = styled(Wrapper)`
  margin: 0;
  padding: 9px 16px;
  height: 40px;
  display: inline-flex;

  & ${Title} {
    font-weight: normal;
  }
`;

import { getToken } from "@asantech/common/react/localStorage";
import { eventsWsUri } from "api/endpoints";
import { backendRootUrl } from "config";
import React, { useEffect, useState } from "react";
import ReconnectingWebSocket from "reconnecting-websocket";

export const WebSocketContext =
  React.createContext<ReconnectingWebSocket | null>(null);

export const WebSocketProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [socket, setSocket] = useState<ReconnectingWebSocket | null>(null);

  useEffect(() => {
    const token = getToken();

    if (!token) {
      console.log("Tried to use a socket without a token present");
      return;
    }

    const wsConn = new ReconnectingWebSocket(
      `${backendRootUrl.replace(/^http/, "ws")}${eventsWsUri}?token=${token}`,
      undefined,
      { connectionTimeout: 1000 }
    );

    const closeIfNoToken = () => {
      if (!getToken()) wsConn.close();
    };

    // Prevent reconnection when unauthenticated
    wsConn.addEventListener("close", closeIfNoToken);
    wsConn.addEventListener("error", closeIfNoToken);

    setSocket(wsConn);
  }, []);

  return (
    <WebSocketContext.Provider value={socket}>
      {children}
    </WebSocketContext.Provider>
  );
};

import React from "react";
import { useSecondsAgo } from "../../../common/hooks/use-seconds-ago";
import { DetectionStatusWithUpdateTime } from "../../../store/Cameras/useAlerts";

interface Props {
  deviceStatus: DetectionStatusWithUpdateTime;
}

export const CameraTileDebugInfo = (props: Props) => {
  const { deviceStatus } = props;
  const frameReadSecAgo = useSecondsAgo(new Date(deviceStatus.timestamp));
  const messageReceivedSecAgo = useSecondsAgo(deviceStatus.messageReceivedAt);

  return (
    <>
      <br />
      alert {deviceStatus.alertOn ? "on" : "off"} {frameReadSecAgo} seconds ago
      <br />
      updated {messageReceivedSecAgo} seconds ago
    </>
  );
};

import { useMountEffect } from "@react-hookz/web";
import { ChildrenOnly } from "common/types";
import { format, parseISO } from "date-fns";
import { createContext, useContext, useMemo } from "react";
import { matchPath } from "react-router";
import { useLocation } from "react-router-dom";
import { Event } from "../../../backend/src/events/types";
import { Key, useKeyHandler } from "../common/hooks/use-key-handler";
import { useNavigate } from "../common/hooks/use-navigate";
import { Pages } from "../common/pages";
import {
  ActiveLocationsStore,
  useActiveLocations,
} from "./Cameras/useActiveLocations";
import { AlertsStore, useTrespassingAlertList } from "./Cameras/useAlerts";
import {
  CameraSettingsStore,
  useCameraSettings,
} from "./Cameras/useCameraSettings";
import { DeviceStore, useDevices } from "./Cameras/useDevices";

const ALERT_DAY_FORMAT = "EEEE, LLLL d";

export type AlertsByDay = Record<string, Event[]>;

export type CamerasContextType = CameraSettingsStore &
  ActiveLocationsStore &
  AlertsStore &
  DeviceStore & { alertsByDay: AlertsByDay };

export const CamerasContext = createContext<CamerasContextType | undefined>(
  undefined
);

export const CamerasContextProvider = ({ children }: ChildrenOnly) => {
  const cameraSettings = useCameraSettings();
  const { doFetchCamerasSettings } = cameraSettings;

  const redirectToDashboard = useNavigate("/");
  useKeyHandler(Key.ESCAPE, redirectToDashboard);

  const activeLocations = useActiveLocations();

  const { pathname } = useLocation();
  const match = matchPath<{ deviceId?: string }>(pathname, {
    path: Pages.Camera,
  });
  const selectedDeviceId = match?.params?.deviceId;
  const devicesIdsTracingEvents = useMemo(
    () =>
      selectedDeviceId
        ? [selectedDeviceId]
        : activeLocations.devices.map((value) => value.id),
    [activeLocations.devices, selectedDeviceId]
  );
  const alerts = useTrespassingAlertList(devicesIdsTracingEvents);

  const devices = useDevices();
  const { doFetchDevicesWithGroups } = devices;

  const alertsByDay = useMemo(
    () =>
      alerts.alerts.reduce((result: AlertsByDay, item: Event) => {
        const dayStr = format(parseISO(item.firstSeen), ALERT_DAY_FORMAT);
        return {
          ...result,
          [dayStr]: (result[dayStr] || []).concat(item),
        };
      }, {}),
    [alerts.alerts]
  );

  useMountEffect(() => {
    void doFetchDevicesWithGroups();
    void doFetchCamerasSettings();
  });

  const initialState: CamerasContextType = {
    ...devices,
    ...cameraSettings,
    ...activeLocations,
    ...alerts,
    alertsByDay,
  };

  return (
    <CamerasContext.Provider value={initialState}>
      {children}
    </CamerasContext.Provider>
  );
};

export const useCameras = (): CamerasContextType => {
  const context = useContext(CamerasContext);
  if (context === undefined)
    throw new Error("useCameras must be used within CamerasContextProvider");
  return context;
};

import client from "api/client";

import { eventsUri } from "./endpoints";
import { Event } from "../../../backend/src/events/types";

export async function getEvents(
  beforeId?: string,
  deviceId?: string[]
): Promise<Event[]> {
  const response = await client.get<Event[]>(eventsUri, {
    params: {
      ...(beforeId ? { beforeId } : {}),
      ...(deviceId ? { deviceId } : {}),
    },
  });
  return response.data;
}

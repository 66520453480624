import { useToggle } from "@react-hookz/web";
import { ReactComponent as CloseIcon } from "assets/svgs/close-icon.svg";
import { ReactComponent as DeleteIcon } from "assets/svgs/delete-icon.svg";
import { handleFormSubmitError } from "common/formUtils";
import {
  TooltipDialog,
  TooltipDialogContainer,
  TooltipDialogContent,
  TooltipDialogFooter,
} from "components";
import { Button } from "components/Button";
import { useCallback, useEffect, useMemo, useState } from "react";
import { UseFormReturn } from "react-hook-form";
import { toast } from "react-toastify";
import styled from "styled-components";
import { AlertRule } from "../../../../backend/src/alerts/types";
import { useAlerts } from "../../store/Alerts.context";
import { AlertRuleForm, RuleForm } from "./RuleForm";

type Props = {
  form: UseFormReturn<AlertRuleForm>;
  editMode: boolean;
  showDiscardDialog: boolean;
  toggleDiscardDialog: (show?: boolean) => void;
  toggleEditMode: (show?: boolean) => void;
};

export const Sidebar = ({
  form,
  editMode,
  showDiscardDialog,
  toggleDiscardDialog,
  toggleEditMode,
}: Props) => {
  const {
    doCreateRule,
    doUpdateRule,
    doDeleteRule,
    rulesList,
    selectedRuleId,
    selectRuleId,
    addingNewRule,
    toggleAddingNewRule,
  } = useAlerts();
  const [isResetting, toggleResetting] = useToggle(false);
  const [showDeleteDialog, toggleDeleteDialog] = useToggle(false);
  const detailsTitle = editMode ? "Editing" : "Details";
  const selectedRule = useMemo(
    () => rulesList.find((rule) => rule.id === selectedRuleId),
    [rulesList, selectedRuleId]
  );
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { handleSubmit, formState, reset, trigger, setError } = form;

  const handleShowDiscardDialog = useCallback(() => {
    if (formState.isDirty) return toggleDiscardDialog(true);

    toggleEditMode(false);
    toggleAddingNewRule(false);
  }, [
    toggleEditMode,
    toggleAddingNewRule,
    toggleDiscardDialog,
    formState.isDirty,
  ]);

  const handleSaveRule = useCallback(() => {
    void handleSubmit(async (formData: AlertRuleForm) => {
      try {
        setIsSubmitting(true);
        const reqData: AlertRule = {
          ...formData,
          recipients: formData.recipients.map((r) => r.phoneNumber),
        };
        const rule = await (addingNewRule
          ? doCreateRule(reqData)
          : doUpdateRule(selectedRuleId, reqData));

        toggleEditMode(false);
        if (rule) selectRuleId(rule.id);
        setIsSubmitting(false);
        toast.success("Rule was saved successfully.");
      } catch (error) {
        setIsSubmitting(false);
        handleFormSubmitError(error, setError);
      }
    })();
  }, [
    doCreateRule,
    doUpdateRule,
    addingNewRule,
    setError,
    selectedRuleId,
    selectRuleId,
    handleSubmit,
    toggleEditMode,
  ]);

  const handleDeleteRule = useCallback(async () => {
    try {
      setIsSubmitting(true);
      await doDeleteRule(selectedRuleId);
      setIsSubmitting(false);
      selectRuleId("");
      toggleDeleteDialog(false);
      toast.success("Rule was deleted successfully.");
    } catch (error) {
      setIsSubmitting(false);
      handleFormSubmitError(error, setError);
    }
  }, [
    selectedRuleId,
    doDeleteRule,
    selectRuleId,
    setError,
    toggleDeleteDialog,
  ]);

  const handleFormReset = useCallback(() => {
    const defaultRule: Partial<AlertRule> = { recipients: [""] };
    const { id, userId, accountId, ...rule } = selectedRule || defaultRule;
    const formData = {
      ...rule,
      recipients: rule.recipients?.map((r) => ({ phoneNumber: r })),
    };
    reset(formData);
  }, [reset, selectedRule]);
  const handleDiscardEditMode = useCallback(() => {
    toggleEditMode(false);
    toggleAddingNewRule(false);
    toggleDiscardDialog(false);
    handleFormReset();
  }, [
    toggleEditMode,
    toggleAddingNewRule,
    toggleDiscardDialog,
    handleFormReset,
  ]);
  const handleHideDiscardDialog = useCallback(() => {
    toggleDiscardDialog(false);
  }, [toggleDiscardDialog]);

  useEffect(() => {
    handleFormReset();
    if (selectedRule) toggleResetting(true);
  }, [handleFormReset, selectedRule, toggleResetting]);

  useEffect(() => {
    if (isResetting) {
      void trigger();
      toggleResetting(false);
    }
  }, [trigger, isResetting, toggleResetting]);

  return (
    <Container>
      <Header>
        <HeaderTitle>
          <Name>{addingNewRule ? "Adding new rule" : detailsTitle}</Name>
        </HeaderTitle>
        {(editMode || addingNewRule) && (
          <HeaderButtonsWrapper>
            <Button
              disabled={isSubmitting || !formState.isValid}
              variant="quaternary"
              onClick={handleSaveRule}
            >
              Save
            </Button>
            <TooltipDialog
              id="discard-dialog"
              isOpen={showDiscardDialog}
              onDismiss={handleHideDiscardDialog}
              content={
                <TooltipDialogContainer>
                  <TooltipDialogContent>
                    Are you sure you want
                    <br /> to discard all changes?
                  </TooltipDialogContent>
                  <TooltipDialogFooter>
                    <Button onClick={handleDiscardEditMode}>Discard</Button>
                    <Button onClick={handleHideDiscardDialog}>
                      Don&apos;t
                    </Button>
                  </TooltipDialogFooter>
                </TooltipDialogContainer>
              }
            >
              <Button onClick={handleShowDiscardDialog} disabled={isSubmitting}>
                <CloseBtnIcon />
              </Button>
            </TooltipDialog>
          </HeaderButtonsWrapper>
        )}
        {!editMode && !addingNewRule && selectedRule && (
          <HeaderButtonsWrapper>
            <Button onClick={() => toggleEditMode()}>Edit</Button>
            <TooltipDialog
              isOpen={showDeleteDialog}
              onDismiss={() => toggleDeleteDialog(false)}
              content={
                <TooltipDialogContainer>
                  <TooltipDialogContent>
                    Are you sure you want
                    <br /> to delete this rule?
                  </TooltipDialogContent>
                  <TooltipDialogFooter>
                    <Button onClick={handleDeleteRule}>Delete</Button>
                    <Button onClick={() => toggleDeleteDialog()}>
                      Don&apos;t
                    </Button>
                  </TooltipDialogFooter>
                </TooltipDialogContainer>
              }
            >
              <Button withIconMiddle onClick={() => toggleDeleteDialog(true)}>
                <DeleteIcon />
              </Button>
            </TooltipDialog>
          </HeaderButtonsWrapper>
        )}
      </Header>
      <Content>
        {!editMode && !selectedRule && !addingNewRule && (
          <EmptySidebarMessage>
            Select a rule to view details
          </EmptySidebarMessage>
        )}
        {(addingNewRule || selectedRuleId) && (
          <RuleForm form={form} isEditing={addingNewRule || editMode} />
        )}
      </Content>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  background: var(--tertiary);
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px 3px 6px #00000029;
  padding: 8px 16px;
  min-height: 58px;
  position: sticky;
  z-index: 2;
  top: 0px;
  font-weight: bold;
`;

const HeaderTitle = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;
`;

const HeaderButtonsWrapper = styled.div`
  white-space: nowrap;

  & > * + * {
    margin-left: 10px;
  }
`;

const Content = styled.div`
  position: relative;
  padding: 24px;
  flex: 1;
  overflow-y: auto;
`;

const Name = styled.span`
  font-size: 16px;
  font-weight: 700;
  color: var(--text-primary);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const EmptySidebarMessage = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--text-dim);
`;

const CloseBtnIcon = styled(CloseIcon)`
  width: 14px;
  height: 14px;
  font-size: 0;
  position: relative;
  top: 1px;
`;

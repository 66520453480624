import { useAsync, useMap } from "@react-hookz/web";
import { useCallback } from "react";
import { CameraSettings } from "../../../../backend/src/devices/types";
import { handleError } from "../../api/client";
import { getCamerasSettings, updateCameraSettings } from "../../api/devices";
import { getFetchErrorMsg } from "../../common/utils";

export type CameraSettingsStore = {
  camerasSettingsById: Map<string, CameraSettings>;
  isLoadingSettings: boolean;
  cameraSettingsSavePending: boolean;
  doUpdateCameraSettings: (settings: CameraSettings) => Promise<void>;
};

export const useCameraSettings = () => {
  const camerasSettingsById = useMap<string, CameraSettings>([]);
  const setSettings = useCallback(
    (values: CameraSettings[]) => {
      camerasSettingsById.clear();
      values.forEach((value) => camerasSettingsById.set(value.deviceId, value));
    },
    [camerasSettingsById]
  );

  const [cameraSettingsState, { execute: doFetchCamerasSettings }] = useAsync(
    async () => {
      try {
        const settings = await getCamerasSettings();
        setSettings(settings);
      } catch (error) {
        handleError(error, getFetchErrorMsg("camera settings"));
      }
    }
  );

  const [updateSettingsState, { execute: doUpdateCameraSettings }] = useAsync(
    async (settings: CameraSettings) => {
      try {
        const data = await updateCameraSettings(settings);
        const { deviceId } = data;
        camerasSettingsById.set(deviceId, data);
      } catch (error) {
        handleError(error, "Something went wrong...");
      }
    }
  );

  return {
    doUpdateCameraSettings,
    updateSettingsState,
    doFetchCamerasSettings,
    camerasSettingsById,
    isLoadingSettings:
      cameraSettingsState.status === "not-executed" ||
      cameraSettingsState.status === "loading",
    cameraSettingsSavePending: updateSettingsState.status === "loading",
  };
};

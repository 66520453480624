import { EnvUrls } from "@asantech/common/express/types";
import {
  HamburgerMenu as Menu,
  HamburgerMenuProps as MenuProps,
} from "@asantech/common/react/HamburgerMenu";
import { getAppUrls } from "api/appUrls";
import { Pages } from "common/pages";
import React, { useEffect, useState } from "react";

const menuLinks = [
  {
    label: "Dashboard",
    to: Pages.Home,
  },
  {
    label: "SMS Alerts",
    to: Pages.AlertRules,
  },
];

export type HamburgerMenuProps = Pick<MenuProps, "isOpen" | "toggle">;

export const HamburgerMenu: React.FC<HamburgerMenuProps> = (props) => {
  const [appUrls, setAppUrls] = useState<EnvUrls>({});

  useEffect(() => {
    const fetchUrls = async () => {
      setAppUrls(await getAppUrls());
    };
    fetchUrls().catch(console.error);
  }, []);

  return (
    <Menu {...props} currentApp="arsc" pages={menuLinks} appUrls={appUrls} />
  );
};

import { useList } from "@react-hookz/web";
import { ReactComponent as InfoIcon } from "assets/svgs/alert-info-icon.svg";
import { TooltipDialog } from "components";
import { Button } from "components/Button";
import { Tag } from "components/Tag";
import { TagsDropdown } from "components/TagsDropdown";
import { useCallback, useEffect, useMemo } from "react";
import { UseFormReturn } from "react-hook-form";
import styled from "styled-components";
import { useAlerts } from "../../store/Alerts.context";
import { RuleCard } from "./RuleCard";
import { AlertRuleForm } from "./RuleForm";

type Props = {
  form: UseFormReturn<AlertRuleForm>;
  toggleDiscardDialog: (show?: boolean) => void;
  toggleEditMode: (show?: boolean) => void;
};

export const RuleCards = ({
  form,
  toggleDiscardDialog,
  toggleEditMode,
}: Props) => {
  const {
    rulesList,
    rulesListLoading,
    selectedRuleId,
    selectRuleId,
    deviceGroupsListLoading,
    deviceGroupsDropdownOptions,
    deviceGroupsGroupNames,
    addingNewRule,
    toggleAddingNewRule,
  } = useAlerts();
  const [
    selectedGroups,
    { set: setSelectedGroups, removeAt: removeSelectedGroupAt },
  ] = useList<string>([]);
  const selectedRules = useMemo(() => {
    if (!selectedGroups.length) return rulesList;
    return rulesList.filter((r) =>
      r.deviceGroupIds.some((id) => selectedGroups.includes(id))
    );
  }, [rulesList, selectedGroups]);
  const handleShowDiscardDialog = useCallback(() => {
    if (form.formState.isDirty) return toggleDiscardDialog(true);
    toggleAddingNewRule(true);
  }, [toggleAddingNewRule, toggleDiscardDialog, form.formState.isDirty]);

  useEffect(() => {
    if (!deviceGroupsListLoading)
      setSelectedGroups(Object.keys(deviceGroupsGroupNames));
  }, [deviceGroupsGroupNames, setSelectedGroups, deviceGroupsListLoading]);

  return (
    <Container>
      <Header>
        <span>
          SMS alerts rules
          <TooltipDialog
            id="rules-triggering"
            content={
              <span>
                We send an SMS immediately when a rule is triggered.
                <br />
                <br />
                When multiple rules are triggered at the same time for the same
                <br />
                phone number, messages are joined to a single SMS.
              </span>
            }
          >
            <StyledInfoIcon />
          </TooltipDialog>
        </span>
        {!addingNewRule && (
          <Button onClick={handleShowDiscardDialog}>Add rule</Button>
        )}
      </Header>
      <Bar>
        <DropdownWrapper>
          <TagsDropdown
            placeholder="Select device group"
            isLoading={deviceGroupsListLoading}
            options={deviceGroupsDropdownOptions}
            selectedItems={selectedGroups}
            setSelectedItems={setSelectedGroups}
          />
        </DropdownWrapper>
        {[...selectedGroups].map((g, i) => (
          <Tag
            key={g}
            id={g}
            name={deviceGroupsGroupNames[g] || ""}
            onRemove={() => removeSelectedGroupAt(i)}
          />
        ))}
      </Bar>
      <RulesWrapper>
        {selectedRules.map((rule, i) => (
          <RuleCard
            key={`rule_${i}`}
            onCardSelect={selectRuleId}
            onToggleDiscardDialog={toggleDiscardDialog}
            onToggleEditMode={toggleEditMode}
            isSelected={rule.id === selectedRuleId}
            isFormDirty={form.formState.isDirty}
            rule={rule}
          />
        ))}
        {!selectedRules.length && (
          <RulesTextWrapper>
            <span>
              {deviceGroupsListLoading || rulesListLoading
                ? "Loading"
                : "Nothing found"}
            </span>
          </RulesTextWrapper>
        )}
      </RulesWrapper>
    </Container>
  );
};

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-x: auto;
  min-width: 300px;
`;

const Header = styled.div`
  padding: 8px 16px;
  min-height: 58px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-weight: bold;
  background-color: var(--secondary);
`;

const Bar = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
  padding: 16px;
  padding-top: 0;

  & > * {
    margin-top: 16px;
  }
`;

const DropdownWrapper = styled.div`
  margin-right: 24px;
`;

const RulesWrapper = styled.div`
  overflow-y: auto;
  margin-right: 16px;
  flex: 1;
`;

const RulesTextWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: var(--text-dim);
`;

const StyledInfoIcon = styled(InfoIcon)`
  margin: 0 10px;
  position: relative;
  top: 1px;
`;

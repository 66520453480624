import { useAsync, useList, useToggle } from "@react-hookz/web";
import {
  createAlertRule,
  deleteAlertRule,
  fetchAlertRules,
  updateAlertRule,
} from "api/alerts";
import { handleError } from "api/client";
import { getDeviceGroups } from "api/devices";
import { ChildrenOnly } from "common/types";
import { getFetchErrorMsg } from "common/utils";
import { DropdownOption } from "components/TagsDropdown";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { AlertRule, AlertRuleBase } from "../../../backend/src/alerts/types";
import { DeviceGroup } from "../../../backend/src/devices/types";

export type AlertsContextType = {
  rulesList: AlertRule[];
  rulesListLoading: boolean;
  rulesListError?: Error;
  deviceGroupsList?: DeviceGroup[];
  deviceGroupsListLoading: boolean;
  deviceGroupsListError?: Error;
  deviceGroupsDropdownOptions: DropdownOption[];
  deviceGroupsGroupNames: Record<string, string>;
  addingNewRule: boolean;
  toggleAddingNewRule: React.Dispatch<React.SetStateAction<boolean>>;
  selectedRuleId: string;
  selectRuleId: (ruleId: string) => void;
  doCreateRule: (payload: AlertRuleBase) => Promise<AlertRule>;
  doUpdateRule: (id: string, payload: AlertRuleBase) => Promise<AlertRule>;
  doDeleteRule: (id: string) => Promise<AlertRule>;
};

export const AlertsContext = createContext<AlertsContextType | undefined>(
  undefined
);

export const useAlerts = (): AlertsContextType => {
  const context = useContext(AlertsContext);

  if (context === undefined)
    throw new Error("useAlerts must be used within AlertsContextProvider");
  return context;
};

export const AlertsContextProvider = ({ children }: ChildrenOnly) => {
  const [addingNewRule, toggleAddingNewRule] = useToggle(false);
  const [selectedRuleId, setSelectedRuleId] = useState("");
  const [
    rulesList,
    {
      set: setRulesList,
      update: updateRuleBy,
      insertAt: insertRule,
      filter: filterRules,
    },
  ] = useList<AlertRule>([]);
  const [
    { status: rulesListStatus, error: rulesListError },
    { execute: doFetchRules },
  ] = useAsync(async () => {
    try {
      const response = await fetchAlertRules();

      setRulesList(response.data);
      return response.data;
    } catch (error) {
      handleError(error, getFetchErrorMsg("rules"));
    }
  }, []);
  const [
    {
      result: deviceGroupsList,
      status: deviceGroupsListStatus,
      error: deviceGroupsListError,
    },
    { execute: doFetchDeviceGroups },
  ] = useAsync(async () => {
    try {
      return await getDeviceGroups();
    } catch (error) {
      handleError(error, getFetchErrorMsg("device groups"));
    }
  }, []);

  const deviceGroupsDropdownOptions = useMemo(
    () =>
      (deviceGroupsList || []).map((g) => ({
        value: g.deviceGroupId,
        label: g.deviceGroupName,
      })),
    [deviceGroupsList]
  );

  const deviceGroupsGroupNames = useMemo(
    () =>
      (deviceGroupsList || []).reduce(
        (acc, g) => ({ ...acc, [g.deviceGroupId]: g.deviceGroupName }),
        {} as Record<string, string>
      ),
    [deviceGroupsList]
  );

  const selectRuleId = useCallback((ruleId: string) => {
    setSelectedRuleId((prevRuleId) => (prevRuleId === ruleId ? "" : ruleId));
  }, []);

  const doCreateRule = useCallback(
    async (payload: AlertRuleBase) => {
      const response = await createAlertRule(payload);

      if (response.data) insertRule(0, response.data);
      return response.data;
    },
    [insertRule]
  );

  const doUpdateRule = useCallback(
    async (id: string, payload: AlertRuleBase) => {
      const response = await updateAlertRule(id, payload);

      if (response.data)
        updateRuleBy((item) => item.id === response.data.id, response.data);

      return response.data;
    },
    [updateRuleBy]
  );

  const doDeleteRule = useCallback(async () => {
    const response = await deleteAlertRule(selectedRuleId);
    if (response.status === 200) {
      selectRuleId("");
      filterRules((r) => r.id !== selectedRuleId);
    }

    return response.data;
  }, [selectedRuleId, selectRuleId, filterRules]);

  useEffect(() => {
    void doFetchDeviceGroups();
    void doFetchRules();
  }, [doFetchRules, doFetchDeviceGroups]);

  useEffect(() => {
    if (addingNewRule) setSelectedRuleId("");
  }, [addingNewRule]);

  useEffect(() => {
    if (selectedRuleId) toggleAddingNewRule(false);
  }, [selectedRuleId, toggleAddingNewRule]);

  const initialState: AlertsContextType = {
    rulesList,
    rulesListLoading:
      rulesListStatus === "not-executed" || rulesListStatus === "loading",
    rulesListError,
    deviceGroupsList,
    deviceGroupsListLoading:
      deviceGroupsListStatus === "not-executed" ||
      deviceGroupsListStatus === "loading",
    deviceGroupsListError,
    deviceGroupsDropdownOptions,
    deviceGroupsGroupNames,
    addingNewRule,
    toggleAddingNewRule,
    selectedRuleId,
    selectRuleId,
    doCreateRule,
    doUpdateRule,
    doDeleteRule,
  };

  return (
    <AlertsContext.Provider value={initialState}>
      {children}
    </AlertsContext.Provider>
  );
};

import React from "react";

import { ReactComponent as CloseIcon } from "assets/svgs/close-icon.svg";
import { Button } from "components";
import styled from "styled-components";

interface Props {
  name: string;
  value: string;
  onButtonClick: (value: string) => void;
}

export const LocationTag: React.FC<Props> = ({
  name,
  value,
  onButtonClick,
}) => {
  return (
    <Wrapper>
      <Name data-testid={`location-tag-${value}`}>{name}</Name>
      <Button withIcon onClick={() => onButtonClick(value)} variant="tertiary">
        <CloseIcon />
      </Button>
    </Wrapper>
  );
};

const Wrapper = styled.span`
  display: inline-block;
  margin-right: 16px;
  background: var(--tertiary);
  border-radius: 4px;
`;

const Name = styled.span`
  display: inline-block;
  padding: 9.5px 4px 9.5px 16px;
`;

import { merge, noDuplicates } from "../utils/arrayUtils";
import * as Buffer from "buffer";

export const groupedDetectionClasses: Record<string, string[]> = {
  person: ["person"],
  vehicle: ["bus", "car", "truck", "vehicle"],
};

export const validDetectionGroups = Object.keys(groupedDetectionClasses);

export const validDetectionClasses = noDuplicates(
  merge(Object.values(groupedDetectionClasses))
);

export type DetectionClass = typeof validDetectionClasses[number];

export type DetectionGroup = typeof validDetectionGroups[number];

/**
 * Events displayed in the event feed.
 */
export interface EventDb {
  id: string;
  deviceId: string;
  firstSeen: Date;
  lastSeen: Date;
  occurrencesCount: number;
  occurrences: Date[];
}

/**
 * Events displayed in the event feed, as returned by the arsc-web API
 */
export type Event = Omit<EventDb, "firstSeen" | "lastSeen" | "occurrences"> & {
  firstSeen: string;
  lastSeen: string;
  occurrences: string[];
} & { detections: EventDetectionsResponse[] };

export type EventDetectionsResponse = {
  imageId: string;
  coordinates: CoordinatesByDetectionClass;
};

export type EventDetectionsDb = {
  id: string;
  eventId: string;
  thumbnail: Buffer;
  createdAt: Date;
  coordinates: CoordinatesByDetectionClass;
};

export type EventDetectionsInputDB = {
  thumbnail?: Buffer;
  coordinates?: string;
  eventId: string;
};

export type EventDetectionsInput = {
  thumbnail: string;
  coordinates: CoordinatesByDetectionClass;
};

export type EventWithDetections = EventDb & {
  detections: EventDetectionsResponse[];
};
/**
 * Data needed to create entries in events table in db
 */
export type EventDbInput = Omit<EventDb, "id" | "createdAt">;

/**
 * Data needed to create Events
 */
export type EventInput = Pick<EventDbInput, "deviceId"> & {
  createdAt: Date;
  detections: EventDetectionsInput[];
};

export type Coordinate = [number, number]; // x, y
export type CoordinatesByDetectionClass = Record<
  DetectionClass,
  { id: number; point?: Coordinate; bbox?: [Coordinate, Coordinate] }[]
>;

/**
 * Data received from arsc-detection as returned by the arsc-web websocket
 */
export type DetectionStatus = {
  deviceId: string;
  alertOn: boolean;
  scanOn: boolean;
  timestamp: Date;

  // Event ID that this status caused, updated or was combined to
  eventId: string | undefined;
  coordinates?: CoordinatesByDetectionClass;
};

/**
 * Data received from arsc-detection
 */
export type AreaAlert = {
  deviceId: string;
  timestamp: string;
  alertOn: boolean;
  coordinates?: CoordinatesByDetectionClass;
  thumbnail?: string;
};

export type BBox = {
  top: number;
  left: number;
  width: number;
  height: number;
};

export type Detection = {
  class: string;
  boundingBox: BBox;
};

export const openApiEventInput = {
  type: "object",
  properties: {
    deviceId: {
      type: "string",
      description: "Device ID that event occurred at",
    },
    createdAt: {
      type: "string",
      format: "date-time",
      description: "timestamp of created event",
    },
    detectedClasses: {
      type: "array",
      description: "list of classes that caused the event",
      items: {
        type: "string",
        enum: validDetectionClasses,
        description: "a class of a detected object",
        example: "person",
      },
    },
  },
};

export const openApiEvent = {
  type: "object",
  properties: {
    ...openApiEventInput.properties,
    id: {
      type: "string",
      description: "Unique event id",
    },
  },
};

export const openApiCoordinate = {
  type: "array",
  items: {
    type: "object",
    required: ["id"],
    properties: {
      id: {
        type: "number",
      },
      point: {
        type: "array",
        minItems: 2,
        maxItems: 2,
        items: {
          type: "number",
          minimum: 0,
          maximum: 1,
        },
      },
      bbox: {
        type: "array",
        items: {
          type: "array",
          minItems: 2,
          maxItems: 2,
          items: {
            type: "number",
            minimum: 0,
            maximum: 1,
          },
        },
      },
    },
  },
};

export const openApiAreaAlert = {
  type: "object",
  required: ["deviceId", "timestamp", "alertOn"],
  properties: {
    deviceId: {
      type: "string",
      description: "Device ID that event occurred at",
    },
    timestamp: {
      type: "string",
      format: "date-time",
      description: "timestamp of created event",
    },
    alertOn: {
      type: "boolean",
      description: "Is alert on",
    },
    detectedClasses: {
      type: "array",
      description: "list of classes that caused the event",
      items: {
        type: "string",
        enum: validDetectionClasses,
        description: "a class of a detected object",
        example: "person",
      },
    },
    thumbnail: {
      type: "string",
      description: "Detection Image encoded as Base64",
    },
    coordinates: {
      type: "object",
      description: "bounding box center coordinates by detected classes",
      properties: {
        person: openApiCoordinate,
        vehicle: openApiCoordinate,
        bus: openApiCoordinate,
        car: openApiCoordinate,
        truck: openApiCoordinate,
      },
    },
  },
};

import { usePrevious } from "@react-hookz/web";
import { useEffect, useState } from "react";

/**
 * When value is passed into it it keeps it's state for given time after original one changes
 * @param delayMs determines time to clear value in ms
 */
export const useDelayedValue = <T>(value: T, delayMs = 3000) => {
  const [delayedValue, setDelayedValue] = useState<T | undefined>(
    value || undefined
  );
  const previousValue = usePrevious(value);
  const [, setTimeoutHandler] = useState<
    ReturnType<typeof setTimeout> | undefined
  >();

  useEffect(() => {
    if (value) {
      setDelayedValue(value);
    }
    if (!value && previousValue) {
      setTimeoutHandler((handler) => {
        if (handler) {
          clearTimeout(handler);
        }
        return setTimeout(() => {
          setDelayedValue(undefined);
        }, delayMs);
      });
    }
  }, [value, previousValue, delayMs]);

  return delayedValue;
};

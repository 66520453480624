import React, { ReactNode, useCallback, useState } from "react";
import { Resizable, ResizeCallbackData } from "react-resizable";
import styled from "styled-components";

const minFeedSize = 384;
const handleWidth = 10;

type Props = {
  width?: number;
  children?: ReactNode;
};

export const ResizablePane: React.FC<Props> = ({
  width: defaultWidth = minFeedSize,
  children,
}) => {
  const [width, setWidth] = useState(Math.max(defaultWidth, minFeedSize));
  const onResize = useCallback(
    (_: React.SyntheticEvent, data: ResizeCallbackData) => {
      setWidth(data.size.width);
    },
    []
  );

  return (
    <Resizable
      resizeHandles={["w"]}
      minConstraints={[minFeedSize, 0]}
      handle={<ResizeHandle />}
      height={0}
      width={width}
      onResize={onResize}
    >
      <ResizeWrapper width={width}>{children}</ResizeWrapper>
    </Resizable>
  );
};

const ResizeHandle = React.forwardRef<HTMLDivElement>((props, ref) => {
  return <ResizeHandleElement ref={ref} {...props} />;
});

ResizeHandle.displayName = "ResizeHandle";

const ResizeHandleElement = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: ${handleWidth}px;
  cursor: col-resize;
  user-select: none;
  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
`;

const ResizeWrapper = styled.div<{ width: number }>`
  width: ${({ width }) => width}px;
  position: relative;
  flex-shrink: 0;
  display: flex;
`;

import App from "App";
import Playable from "playable";
import HLSAdapter from "playable/dist/src/adapters/hls";
import React from "react";
import { createRoot } from "react-dom/client";
import "styles/asanplayer.css";
import "styles/index.css";
import "styles/react-datetime-picker.css";
import "styles/reset.css";
import packageJson from "../package.json";
import reportWebVitals from "./reportWebVitals";

console.log(`Frontend version: ${packageJson.version}`);

const container = document.getElementById("root");
if (container) {
  createRoot(container).render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
Playable.registerPlaybackAdapter(HLSAdapter);

import client, { AxiosResponse } from "api/client";
import { AlertRule, AlertRuleBase } from "../../../backend/src/alerts/types";
import { alertRules } from "./endpoints";

export const fetchAlertRules = () => client.get<AlertRule[]>(alertRules);

export const createAlertRule = (payload: AlertRuleBase) =>
  client.post<AlertRuleBase, AxiosResponse<AlertRule>>(
    `${alertRules}`,
    payload
  );

export const updateAlertRule = (id: string, payload: AlertRuleBase) =>
  client.put<AlertRuleBase, AxiosResponse<AlertRule>>(
    `${alertRules}/${id}`,
    payload
  );

export const deleteAlertRule = async (id: string) =>
  client.delete<AlertRuleBase, AxiosResponse<AlertRule>>(`${alertRules}/${id}`);

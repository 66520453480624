import { setToken } from "@asantech/common/react/localStorage";
import { useAsync, useMountEffect } from "@react-hookz/web";
import { Pages } from "common/pages";
import { Button, TextField } from "components";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import styled from "styled-components";
import { getAccountsList, login } from "../../../api";

type LoginFormData = {
  username: string;
  password: string;
  accountId: string;
};
export const LoginForm = () => {
  const history = useHistory();
  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
    setError,
    setValue,
  } = useForm<LoginFormData>({
    defaultValues: {
      username: "",
      password: "",
      accountId: "",
    },
    mode: "onChange",
  });

  const [
    { status: statusAccountList, result: multipleAccountsExists },
    { execute: doFetchAccountsList },
  ] = useAsync(async () => {
    const accounts = await getAccountsList();
    if (accounts.length === 1) {
      setValue("accountId", accounts[0]);
    }
    return accounts.length > 1;
  });

  const onSubmit = async (formData: LoginFormData) => {
    try {
      const response = await login(formData);

      if (response.status === 401) {
        setError("username", {});
        setError("password", {});
        return toast.error("Invalid username and / or password");
      }

      if (response.status !== 200) {
        return toast.error("Something went wrong...");
      }

      const { accessToken } = response.data;
      setToken(accessToken);
      history.push(Pages.Home);
    } catch (error) {
      toast.error("Something went wrong...");
    }
  };

  useMountEffect(doFetchAccountsList);

  return (
    <Box>
      <Title>Area Scanner</Title>
      <Paragraph>Sign In</Paragraph>
      <Form onSubmit={handleSubmit(onSubmit)} data-testid="login-form">
        <TextField
          register={register}
          name="username"
          placeholder="Username"
          required={true}
          errors={errors.username}
        />
        <TextField
          register={register}
          name="password"
          type="password"
          placeholder="Password"
          required={true}
          errors={errors.password}
        />

        {multipleAccountsExists && (
          <TextField
            register={register}
            name="accountId"
            type="text"
            placeholder="Customer account ID"
            required={true}
            errors={errors.accountId}
          />
        )}
        <SubmitWrapper>
          <Button
            disabled={
              isSubmitting ||
              statusAccountList === "not-executed" ||
              statusAccountList === "loading"
            }
            type="submit"
          >
            Sign in
          </Button>
        </SubmitWrapper>
      </Form>
    </Box>
  );
};

const Box = styled.div`
  box-shadow: 0px 0px 6px #00000014;
  background-color: var(--secondary);
  max-width: 328px;
  width: 100%;
  padding: 32px;
  border-radius: 8px;
`;

const Title = styled.h1`
  font-size: 32px;
  font-weight: bold;
  color: var(--text-primary);
  line-height: 1;
`;

const Paragraph = styled.p`
  font-size: 14px;
  font-weight: bold;
  color: var(--text-primary);
  margin: 16px 0;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;

  & > *:not(:last-child) {
    margin-bottom: 16px;
  }
`;

const SubmitWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

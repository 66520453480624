import client from "api/client";

import { AccessToken } from "../../../backend/src/users/types";
import { accounts, keepAliveUrl, loginUri } from "./endpoints";
import axios from "axios";
import { backendRootUrl } from "../config";

export function login(credentials: { username: string; password: string }) {
  return client
    .post<AccessToken>(loginUri, credentials)
    .catch((error) => error.response);
}

export async function getAccountsList() {
  return axios
    .get<string[]>(`${backendRootUrl}${accounts}`)
    .then((response) => response.data)
    .catch((error) => error.response);
}

export async function keepAlive() {
  return await client.get(`${keepAliveUrl}`);
}

import { Button } from "components";
import { format, parseISO } from "date-fns";
import { useCallback } from "react";
import { Virtuoso } from "react-virtuoso";
import { useCameras } from "store/Cameras.context";
import styled from "styled-components";
import { AlertBox, Collapse } from "views/cameras";
import { Event } from "../../../../backend/src/events/types";
import { VirtuosoWrapperForTests } from "../../common/testUtils";
import { intersectArray } from "../../common/utils";
import { DetectionsDisplay } from "../cameras/Alert/DetectionsDisplay";
import { useFeedVirtualList } from "./useFeedVirtualList";

type Props = {
  handleAlertBoxClicked?: (alert: Event) => void;
};

const TIME_FORMAT = "HH:mm:ss";

const getDetectionMessage = (alert: Event) => {
  if (!alert.detections || alert.detections.length === 0) {
    return "Breach detected";
  }
  return "";
};

export const Feed = (props: Props) => {
  const { handleAlertBoxClicked } = props;
  const {
    alertsByDay,
    detectionStatusByDevice,
    isLoading: devicesLoading,
    loadMoreAlerts,
    alertsLoading,
    hasMoreAlerts,
    camerasById,
    deviceGroupsById,
    locations,
  } = useCameras();

  const noDevicesLoaded = !devicesLoading && !camerasById.size;
  const {
    data,
    selectedImages,
    setSelectedImages,
    openedGroups,
    toggleOpenGroup,
    virtuosoProps,
  } = useFeedVirtualList(alertsByDay);
  const { firstItemIndex } = virtuosoProps;

  const renderAlert = useCallback(
    (alert: Event) => {
      const device = camerasById.get(alert.deviceId);

      if (!device) return null;

      const locationGroups = locations.map((l) => l.id);
      const commonGroups = intersectArray(
        device.deviceGroupIds || [],
        locationGroups
      );
      const deviceGroupId = commonGroups[0] || (device.deviceGroupIds || [])[0];
      const deviceGroup = deviceGroupId
        ? deviceGroupsById.get(deviceGroupId)
        : null;

      const status = detectionStatusByDevice[alert.deviceId];
      const alertOnForThis =
        status !== undefined && status.alertOn && status.eventId === alert.id;
      const selectedImage = selectedImages[alert.id] || 0;
      const setSelectedImage = (fn: (index: number) => number) =>
        setSelectedImages((prev) => ({
          ...prev,
          [alert.id]: fn(prev[alert.id] || 0),
        }));

      return (
        <AlertBoxWrapper>
          <AlertBox
            key={alert.id}
            variant="error"
            title={device.name}
            description={getDetectionMessage(alert)}
            time={format(parseISO(alert.firstSeen), TIME_FORMAT)}
            occurrencesCount={alert.occurrencesCount}
            firstSeen={alert.firstSeen}
            note={deviceGroup?.name}
            handleAlertClick={() =>
              handleAlertBoxClicked ? handleAlertBoxClicked(alert) : undefined
            }
            isAlertOn={alertOnForThis}
          >
            {alert.detections.length > 0 && (
              <DetectionsDisplay
                key={alert.id + "-detections"}
                selectedImage={selectedImage}
                setSelectedImage={setSelectedImage}
                detections={alert.detections}
              />
            )}
          </AlertBox>
        </AlertBoxWrapper>
      );
    },
    [
      camerasById,
      deviceGroupsById,
      detectionStatusByDevice,
      handleAlertBoxClicked,
      locations,
      selectedImages,
      setSelectedImages,
    ]
  );

  const renderGroup = useCallback(
    (dayStr: string) => (
      <Collapse
        title={dayStr}
        collapsed={!openedGroups.includes(dayStr)}
        onCollapseChange={() => {
          toggleOpenGroup(dayStr);
        }}
      />
    ),
    [openedGroups, toggleOpenGroup]
  );

  const Footer = useCallback(
    () => (
      <LoadMore>
        {!hasMoreAlerts ? <p>All entries have been loaded</p> : ""}
        <Button
          disabled={!hasMoreAlerts || noDevicesLoaded || alertsLoading}
          onClick={loadMoreAlerts}
        >
          {alertsLoading || devicesLoading ? "Loading..." : "Load older events"}
        </Button>
      </LoadMore>
    ),
    [
      alertsLoading,
      devicesLoading,
      hasMoreAlerts,
      loadMoreAlerts,
      noDevicesLoaded,
    ]
  );

  const ListItem = useCallback(
    (index: number) => {
      const eventOrDate = data[index - firstItemIndex];

      if (!eventOrDate) return;
      return typeof eventOrDate === "string"
        ? renderGroup(eventOrDate)
        : renderAlert(eventOrDate);
    },
    [data, renderAlert, renderGroup, firstItemIndex]
  );
  const Header = useCallback(
    () => <Title>Live and historical data of breach events.</Title>,
    []
  );

  return (
    <SideContentScrollable>
      <VirtuosoWrapperForTests>
        <ListContainerStyled
          itemContent={ListItem}
          components={{ Header, Footer }}
          {...virtuosoProps}
        ></ListContainerStyled>
      </VirtuosoWrapperForTests>
    </SideContentScrollable>
  );
};

const AlertBoxWrapper = styled.div`
  padding: 10px 0 5px 10px;
  &:last-of-type {
    margin-bottom: 0;
  }
`;

const Title = styled.p`
  padding: 16px;
  padding-top: 0;
`;

const SideContentScrollable = styled.div`
  height: calc(100% - 37px);
  overflow: hidden;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
`;

const LoadMore = styled.div`
  padding: 5px 16px;
  text-align: center;

  p {
    margin-bottom: 32px;
    text-align: left;
  }
`;

const ListContainerStyled = styled(Virtuoso)`
  flex-grow: 1;
`;

import React from "react";
import { MemoryRouter } from "react-router";
import { Route } from "react-router-dom";
import { WebSocketProvider } from "store/Websocket.context";
import { CamerasContextProvider } from "../store/Cameras.context";
import { Pages } from "./pages";

export const wrapWithCamerasContext = (children: React.ReactNode) => {
  return <CamerasContextProvider>{children}</CamerasContextProvider>;
};

export const wrapWithRouter = (
  children: React.ReactNode,
  path: string = Pages.Home,
  params: Record<string, string> = {}
) => {
  const pathWithParams = Object.keys(params).reduce(
    (previousValue, currentValue) =>
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      previousValue.replace(":" + currentValue, params[currentValue]!),
    path
  );

  return (
    <MemoryRouter initialEntries={[pathWithParams]}>
      <Route path={path}>{children}</Route>
    </MemoryRouter>
  );
};

export const wrapWithWebsocketContext = (props: {
  children: React.ReactNode;
}) => {
  return <WebSocketProvider>{props.children}</WebSocketProvider>;
};

/**
 * This is workaround for the problem that Virtuoso list gets empty in react-testing-library.
 * (if virtuoso rendered and we call setState in parent component list gets empty in tests without this wrapper).
 * If {@link VirtuosoWrapperForTests} used outside of jest it does not change anything.
 *
 * If you read it in future and tests passing without it. Please remove it from source code
 */
export const VirtuosoWrapperForTests = isInsideJest()
  ? (props: { children: React.ReactNode }) => (
      <React.Fragment>
        {[Date.now()].map((el) => (
          <React.Fragment key={el}>{props.children}</React.Fragment>
        ))}
      </React.Fragment>
    )
  : (props: { children: React.ReactNode }) => (
      <React.Fragment>{props.children}</React.Fragment>
    );

function isInsideJest() {
  return process.env.JEST_WORKER_ID !== undefined;
}

import { ReactComponent as UserIcon } from "assets/svgs/user-icon.svg";
import styled from "styled-components";
import { TokenFields } from "../../../../backend/src/users/types";

export const CurrentUser = (props: { userDetails?: TokenFields }) => {
  const { username, accountId } = props?.userDetails || {
    username: "Guest",
    accountId: "",
  };

  return (
    <Wrapper>
      <Name>{`${username}` + (accountId ? `(${accountId})` : "")}</Name>
      <UserIcon />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: inline-flex;
  flex-direction: row;
  align-items: center;
`;

const Name = styled.span`
  display: inline-block;
  color: var(--text-primary);
  text-align: left;
  font-size: 14px;
  font-weight: bold;
  padding-right: 32px;
`;

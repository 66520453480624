import { TooltipDialog } from "@asantech/common/react/Tooltip";
import { ReactComponent as InfoIcon } from "assets/svgs/alert-info-icon.svg";
import React, { ReactElement } from "react";
import { FieldError } from "react-hook-form";
import styled from "styled-components";

type Props = {
  id?: string;
  label?: string | ReactElement;
  error?: FieldError;
  children?: React.ReactNode;
  tooltip?: string | ReactElement;
  className?: string;
};

export const FieldRow = ({
  id,
  label,
  error,
  className,
  tooltip,
  children,
}: Props) => (
  <Wrapper className={className}>
    <LabelText>
      {label}
      {!!tooltip && (
        <TooltipDialog id={id || "row-tooltip"} content={tooltip}>
          <StyledInfoIcon />
        </TooltipDialog>
      )}
    </LabelText>
    {children}
    {!!error && (
      <FieldErrorMessage role="alert">{error.message}</FieldErrorMessage>
    )}
  </Wrapper>
);

export const Wrapper = styled.span`
  color: var(--text-primary);
  margin-bottom: 32px;
`;

export const LabelText = styled.div`
  margin-bottom: 12px;
  font-size: 12px;
`;

const StyledInfoIcon = styled(InfoIcon)`
  margin: 0 10px;
  position: relative;
  top: 1px;
`;

const FieldErrorMessage = styled.p`
  color: var(--live);
  font-weight: 500;
  margin-bottom: 6px;
`;

/**
 * Get config value from localStorage or build time configuration.
 */
function get(key: string): string;
function get(key: string, defaultValue: string): string;
function get(key: string, defaultValue?: string): string | undefined {
  return localStorage.getItem(key) || process.env[key] || defaultValue;
}

const backendRootUrl_ = get("REACT_APP_BACKEND_ROOT_URL", "");

let absoluteBackendRootUrl;
if (backendRootUrl_.startsWith("/")) {
  absoluteBackendRootUrl =
    window.location.protocol + "//" + window.location.host + backendRootUrl_;
} else {
  absoluteBackendRootUrl = backendRootUrl_;
}
export const backendRootUrl = absoluteBackendRootUrl;
export const useWebSocketsVideoStreams =
  get("REACT_APP_USE_WEBSOCKET_VIDEO_STREAMS") === "true";

export const envName = process.env.REACT_APP_ENV_NAME || "dev";

import {
  DropdownMenu,
  FieldSize,
  FieldVariant,
} from "@asantech/common/react/DropdownMenu";
import { Loader } from "@asantech/common/react/Loader";
import styled from "styled-components";
import { Checkbox } from "../Checkbox";

export interface DropdownOption {
  label: string;
  value: string;
}

interface Props {
  placeholder: string;
  isLoading?: boolean;
  invalid?: boolean;
  options: DropdownOption[];
  selectedItems: string[];
  setSelectedItems: (items: string[]) => void;
  variant?: FieldVariant;
  size?: FieldSize;
  disabled?: boolean;
}

export const TagsDropdown = ({
  placeholder,
  options,
  isLoading,
  invalid,
  selectedItems,
  setSelectedItems,
  variant,
  size,
  disabled,
}: Props) => {
  const handleCheckboxChange = (shouldAdd: boolean, id: string) => {
    const items = shouldAdd
      ? [...selectedItems, id]
      : selectedItems.filter((i) => i !== id);

    setSelectedItems(items);
  };

  return (
    <DropdownMenu
      size={size}
      invalid={invalid}
      variant={variant}
      placeholder={placeholder}
      disabled={disabled}
    >
      <DropdownPlaceholder>
        {isLoading && <DropdownLoader size={20} />}
        {options.map((o) => (
          <DropdownMenuItem key={o.value}>
            <Checkbox
              label={o.label}
              checked={selectedItems.includes(o.value)}
              onChange={(val: boolean) => handleCheckboxChange(val, o.value)}
            />
          </DropdownMenuItem>
        ))}
      </DropdownPlaceholder>
    </DropdownMenu>
  );
};

const DropdownPlaceholder = styled.div`
  min-height: 80px;
`;

const DropdownLoader = styled(Loader)`
  margin: 30px;
`;

const DropdownMenuItem = styled.div`
  border: 0;
  box-shadow: none;
  background: transparent;
  font-size: 14px;
  font-weight: 400;
  color: var(--text-secondary);
  padding: 11px 16px 10px;
  width: 100%;
  text-align: left;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);

  &:hover {
    cursor: pointer;
    background: #2d3035aa;
  }

  &:last-child {
    border-bottom: 0;
  }
`;

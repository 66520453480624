import { useCallback, useEffect } from "react";

export enum Key {
  ESCAPE = "Escape",
}

/**
 * Executes some function when key is pressed
 * @param key
 * @param callback
 */
export const useKeyHandler = (key: Key, callback: () => void) => {
  const onKeyPressed = useCallback(
    (event: { key: string }) => {
      if (event.key === key) {
        callback();
      }
    },
    [callback, key]
  );

  useEffect(() => {
    document.addEventListener("keydown", onKeyPressed, false);
    return () => {
      document.removeEventListener("keydown", onKeyPressed, false);
    };
  }, [onKeyPressed]);
};

import { useMeasure } from "@react-hookz/web";
import { useMemo } from "react";
import styled from "styled-components";
import { CoordinatesByDetectionClass } from "../../../../../backend/src/events/types";
import { AlertTooltip } from "../../../components/AlertTooltip";
import {
  AggregatedDetection,
  useAggregateDetections,
} from "./useAggregateDetections";

interface Props {
  videoWidth: number;
  videoHeight: number;
  coordinates: CoordinatesByDetectionClass;
}

const LABEL_SPACE_PX = 2;

const useSortedCoords = (detectionsCoords: AggregatedDetection[]) => {
  const sortBoxesByArea = (a: AggregatedDetection, b: AggregatedDetection) => {
    if (a.bbox && b.bbox) {
      const aArea =
        (a.bbox[1][0] - a.bbox[0][0]) * (a.bbox[0][1] - a.bbox[1][1]);
      const bArea =
        (b.bbox[1][0] - b.bbox[0][0]) * (b.bbox[0][1] - b.bbox[1][1]);

      if (aArea > bArea) {
        return -1;
      } else if (bArea > aArea) {
        return 1;
      } else {
        return 0;
      }
    }
    return 0;
  };

  //points first
  return detectionsCoords
    .reduce((res, coords) => {
      if (coords.point) {
        return [coords, ...res];
      }
      return [...res, coords];
    }, [] as AggregatedDetection[])
    .sort(sortBoxesByArea);
};

export const DetectionsLayer = (props: Props) => {
  const { videoWidth, videoHeight, coordinates } = props;
  const detectionsCoords = useAggregateDetections(
    coordinates,
    videoHeight,
    videoWidth
  );

  const sortedCoords = useSortedCoords(detectionsCoords);

  return (
    <>
      {sortedCoords.map((detection, i, source) => (
        <DetectionElement
          key={
            detection.id +
            detection.detectionClass +
            JSON.stringify(detection.bbox || detection.point)
          }
          detection={detection}
          width={videoWidth}
          height={videoHeight}
          zIndex={source.length - 1 - i}
        />
      ))}
    </>
  );
};

const DetectionElement = (props: {
  detection: AggregatedDetection;
  width: number;
  height: number;
  zIndex: number;
}) => {
  const [measurements, ref] = useMeasure<HTMLDivElement>();
  const { detection, width, height, zIndex } = props;
  const [elTop, elLeft, elWidth, elHeight] = useMemo(() => {
    const point = detection.point;
    const bbox = detection.bbox;

    if (bbox) {
      const bboxLeftTop = bbox[0];
      const bboxRightBottom = bbox[1];

      return [
        bboxLeftTop[1],
        bboxLeftTop[0],
        bboxRightBottom[0] - bboxLeftTop[0],
        bboxRightBottom[1] - bboxLeftTop[1],
      ];
    }
    if (point) {
      return [point[1], point[0], 5, 5];
    }

    return [0, 0, 0, 0];
  }, [detection.bbox, detection.point]);

  const markerStyle = useMemo(() => {
    return {
      left: elLeft,
      top: elTop,
      width: elWidth,
      height: elHeight,
      zIndex,
    };
  }, [elHeight, elLeft, elTop, elWidth, zIndex]);

  const labelStyle = useMemo(() => {
    const labelSizeW = Math.floor(measurements?.width || 0) + LABEL_SPACE_PX;
    const labelSizeH = Math.floor(measurements?.height || 0) + LABEL_SPACE_PX;

    const outOfViewportX = elWidth + elLeft + labelSizeW > width;
    const outOfViewportY = elTop + labelSizeH > height;

    return {
      right: outOfViewportX
        ? LABEL_SPACE_PX
        : (labelSizeW + LABEL_SPACE_PX) * -1,
      top: outOfViewportY ? (elTop + labelSizeH - height) * -1 : 0,
    };
  }, [elLeft, elTop, elWidth, height, measurements, width]);

  return (
    <DetectionElementWrapper style={markerStyle}>
      <LabelElement style={labelStyle} ref={ref}>
        <AlertTooltip>{detection.detectionClass}</AlertTooltip>
      </LabelElement>
    </DetectionElementWrapper>
  );
};

const DetectionElementWrapper = styled.div`
  position: absolute;
  z-index: 1;
  border: 2px #ffbb0cb2 solid;
  cursor: default;
  &:hover > * {
    display: block;
  }
  &:hover {
    z-index: 2;
  }
`;
const LabelElement = styled.div`
  display: none;
  pointer-events: none;
  position: absolute;
  font-size: 16px;
  cursor: default;
  color: var(--text-primary);
  user-select: none;
  z-index: 1;
`;

import { useVideoMeasure } from "@asantech/common/react/CamPreview/KonvaVideoWrapper";
import { HorizontalNav } from "@asantech/common/react/HorizontalNav";
import { LiveVideo, Resolution } from "@asantech/common/react/LiveVideo";
import { useToggle } from "@react-hookz/web";
import { handleError } from "api/client";
import { getLiveStream } from "api/devices";
import { getCameraLinks } from "common/cameraLinks";
import { useAutoUpdate } from "common/hooks/use-auto-update";
import { useNavigate } from "common/hooks/use-navigate";
import { Pages } from "common/pages";
import { DashboardLayout } from "components";
import { Camera } from "models/cameras";
import { useCallback, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { matchPath } from "react-router";
import { useLocation } from "react-router-dom";
import { useCameras } from "store/Cameras.context";
import styled from "styled-components";
import {
  CameraSettingsForm,
  CameraSettingsSideContent,
} from "views/camera-settings/CameraSettingsSideContent";
import { CameraBarContent } from "views/camera/CameraBarContent";
import { Feed } from "views/feed";
import {
  CameraSettings,
  CornerPos,
} from "../../../../../backend/src/devices/types";
import { validDetectionGroups } from "../../../../../backend/src/events/types";
import { useWebSocketsVideoStreams } from "../../../config";
import { AreaEditor } from "../CamPreview/AreaEditor";
import { Status } from "../CamPreview/Status";

export type Props = {
  cameraData: Camera;
  cameraSettings: CameraSettings;
};

function getCameraSettingsDefaultFormValues(
  cameraSettings: CameraSettings | undefined
) {
  return {
    areaToScan: cameraSettings?.areaToScan || [],
    detectionGroups: cameraSettings?.detectionGroups || validDetectionGroups,
    activitySchedule: cameraSettings?.activitySchedule || [],
    activeTo: cameraSettings?.activeTo && new Date(cameraSettings.activeTo),
  };
}

function formFieldsToDtoSetting(
  formFields: CameraSettingsForm,
  cameraData: Camera
) {
  return {
    deviceId: cameraData.id,
    areaToScan: formFields?.areaToScan || [],
    detectionGroups: formFields?.detectionGroups,
    activitySchedule: formFields?.activitySchedule || [],
    ...(formFields?.activeTo
      ? { activeTo: new Date(formFields?.activeTo) }
      : {}),
  };
}

export const CameraContainer = (props: Props) => {
  const { cameraData, cameraSettings } = props;
  const location = useLocation();
  const isSettingsView = !!matchPath(location.pathname, {
    path: Pages.CameraSettings,
  });
  const { doUpdateCameraSettings, cameraSettingsSavePending } = useCameras();

  const [streamError, setStreamError] = useState<Error>();
  const { setAspectRatio, ...videoMeasure } = useVideoMeasure();

  const cameraSettingsDefault =
    getCameraSettingsDefaultFormValues(cameraSettings);
  const form = useForm<CameraSettingsForm>({
    defaultValues: cameraSettingsDefault,
  });
  const { control, setValue } = form;

  const formFields = useWatch({ control }) as CameraSettingsForm;

  useAutoUpdate(formFields, () =>
    doUpdateCameraSettings(formFieldsToDtoSetting(formFields, cameraData))
  );

  const [drawingActive, toggleDrawing] = useToggle(
    !!cameraSettingsDefault.activitySchedule.length
  );

  const handleCloseBtnClick = useNavigate(Pages.Home);

  const handleDrawingChange = useCallback(
    (corners?: CornerPos[]) => {
      corners && setValue("areaToScan", corners);
    },
    [setValue]
  );
  const handleStreamError = useCallback((error: Error) => {
    setStreamError(error);
    handleError(error);
  }, []);

  return (
    <DashboardLayout
      barContent={
        <CameraBarContent
          handleCloseBtnClick={handleCloseBtnClick}
          cameraData={cameraData}
          error={streamError}
        />
      }
      navContent={<Nav links={getCameraLinks(cameraData.id)} />}
      sideContent={
        isSettingsView ? (
          <CameraSettingsSideContent
            form={form}
            toggleDrawing={toggleDrawing}
            drawingActive={drawingActive}
            isSavePending={cameraSettingsSavePending}
          />
        ) : (
          <Feed />
        )
      }
    >
      <AreaEditor
        drawingActive={drawingActive && isSettingsView}
        cornersInitial={cameraSettings?.areaToScan}
        onDrawingChange={handleDrawingChange}
        videoMeasure={videoMeasure}
        deviceId={cameraData.id}
      >
        {(isSettingsView && drawingActive) || (
          <StatusWrapper>
            <Status id={cameraData.id} error={streamError} />
          </StatusWrapper>
        )}
        <LiveVideo
          useAsanPlayer={useWebSocketsVideoStreams}
          cameraId={cameraData.id}
          resolution={Resolution.LOW}
          onError={handleStreamError}
          setAspectRatio={setAspectRatio}
          getLiveStream={getLiveStream}
        />
      </AreaEditor>
    </DashboardLayout>
  );
};

const Nav = styled(HorizontalNav)`
  && {
    padding: 0;
    display: block;
    width: auto;
    margin-bottom: 16px;
  }

  li {
    display: inline-block;
    width: 50%;
  }

  a {
    font-size: 16px;
    font-weight: bold;
    padding: 14px 15px;
    min-width: auto;
  }
`;

const StatusWrapper = styled.div`
  position: absolute;
  top: 16px;
  right: 16px;
`;

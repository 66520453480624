import { useCallback } from "react";

import { useHistory } from "react-router";

/**
 * Returns memoized navigate function
 */
export const useNavigate = (uri: string) => {
  const history = useHistory();

  const goto = useCallback(() => {
    history.push(uri);
  }, [history, uri]);

  return goto;
};

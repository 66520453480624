import { useToggle } from "@react-hookz/web";
import { ResizablePane } from "components/ResizablePane";
import { TopBar } from "components/TopBar";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import { RuleCards } from "../views/alerts/RuleCards";
import { AlertRuleForm } from "../views/alerts/RuleForm";
import { Sidebar } from "../views/alerts/Sidebar";

export const AlertRulesView = () => {
  const [editMode, toggleEditMode] = useToggle(false);
  const [showDiscardDialog, toggleDiscardDialog] = useToggle(false);

  const form = useForm<AlertRuleForm>({
    mode: "onChange",
    reValidateMode: "onChange",
  });

  return (
    <>
      <TopBar />
      <Content>
        <Main>
          <RuleCards
            form={form}
            toggleEditMode={toggleEditMode}
            toggleDiscardDialog={toggleDiscardDialog}
          />
        </Main>
        <ResizablePane width={481}>
          <Sidebar
            form={form}
            editMode={editMode}
            showDiscardDialog={showDiscardDialog}
            toggleDiscardDialog={toggleDiscardDialog}
            toggleEditMode={toggleEditMode}
          />
        </ResizablePane>
      </Content>
    </>
  );
};

const Content = styled.div`
  display: flex;
  width: 100%;
  height: calc(100% - 55px);
`;

const Main = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-x: auto;
  background: var(--primary);
`;

import { useAsync, useCounter, useList, usePrevious } from "@react-hookz/web";
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { DetectionStatus, Event } from "../../../../backend/src/events/types";
import { handleError } from "../../api/client";
import { getEvents } from "../../api/events";
import { useWebsocket } from "../../common/hooks/use-websocket";
import { getFetchErrorMsg, subtractArray } from "../../common/utils";

const INITIAL_FIRST_ITEM_INDEX = Math.floor(Number.MAX_SAFE_INTEGER / 2);

export type DetectionStatusWithUpdateTime = DetectionStatus & {
  messageReceivedAt: Date;
};
type DetectionStatusByDevice = { [key: string]: DetectionStatusWithUpdateTime };

export type AlertsStore = {
  alerts: Event[];
  detectionStatusByDevice: DetectionStatusByDevice;
  addAlert: (...items: Event[]) => void;
  loadMoreAlerts: () => Promise<void>;
  hasMoreAlerts: boolean;
  doFetchEvents: (beforeId: string) => Promise<void>;
  alertsLoading: boolean;
  firstItemIndex: number;
  setIsFeedAtTop: Dispatch<SetStateAction<boolean>>;
};

export const useTrespassingAlertList = (deviceIds: string[] = []) => {
  const [hasMoreAlerts, setHasMoreAlerts] = useState(true);
  const [isFeedAtTop, setIsFeedAtTop] = useState(true);
  const [firstItemIndex, { dec: decrementFirstItemIndex }] = useCounter(
    INITIAL_FIRST_ITEM_INDEX
  );
  const [detectionStatusByDevice, setDetectionStatusByDevice] =
    useState<DetectionStatusByDevice>({});
  const previousDeviceIds = usePrevious(deviceIds);

  const [
    alerts,
    {
      insertAt,
      updateAt,
      push: appendAlerts,
      clear: clearAlerts,
      filter: filterAlerts,
    },
  ] = useList<Event>([]);

  const [eventsState, { execute: doFetchEvents }] = useAsync(
    async (beforeId?: string) => {
      try {
        const result = await getEvents(beforeId, deviceIds);
        if (result.length === 0) {
          setHasMoreAlerts(false);
        } else {
          setHasMoreAlerts(true);
        }
        appendAlerts(...result);
      } catch (error) {
        handleError(error, getFetchErrorMsg("events"));
      }
    }
  );

  const deviceChanges = useMemo(() => {
    const removedDevices = subtractArray(previousDeviceIds || [], deviceIds);
    const addedDevices = subtractArray(deviceIds, previousDeviceIds || []);

    return {
      removedDevices,
      addedDevices,
    };
  }, [deviceIds, previousDeviceIds]);

  useEffect(() => {
    if (deviceChanges.removedDevices.length && deviceIds.length > 0) {
      filterAlerts(
        (alert) => !deviceChanges.removedDevices.includes(alert.deviceId)
      );
    }
  }, [deviceChanges.removedDevices, deviceIds.length, filterAlerts]);

  useEffect(() => {
    if (deviceChanges.addedDevices.length) {
      clearAlerts();
      void doFetchEvents();
    }
  }, [clearAlerts, deviceChanges.addedDevices.length, doFetchEvents]);

  useEffect(() => {
    if (deviceIds && deviceIds.length === 0) {
      clearAlerts();
      void doFetchEvents();
    }
  }, [clearAlerts, deviceIds, doFetchEvents]);

  const socket = useWebsocket();

  const addAlert = useCallback(
    (alert: Event) => {
      const alertIndex = alerts.map((alert) => alert.id).indexOf(alert.id);
      if (alertIndex === -1) {
        if (!isFeedAtTop) decrementFirstItemIndex();
        insertAt(0, alert);
      } else {
        updateAt(alertIndex, alert);
      }
    },
    [alerts, insertAt, updateAt, isFeedAtTop, decrementFirstItemIndex]
  );

  useEffect(() => {
    if (!socket) {
      return;
    }
    const handleMessage = (message: MessageEvent) => {
      const event = JSON.parse(message.data);

      if (
        event.type === "alert" &&
        (!deviceIds.length || deviceIds.includes(event.payload.deviceId))
      )
        addAlert(event.payload);
    };

    const handleDetectionStatusMessage = (message: MessageEvent) => {
      const event = JSON.parse(message.data);

      if (event.type === "detection_status")
        setDetectionStatusByDevice((prevState) => ({
          ...prevState,
          [event.payload.deviceId]: {
            ...event.payload,
            messageReceivedAt: new Date(),
          },
        }));
    };

    socket.addEventListener("message", handleMessage);
    socket.addEventListener("message", handleDetectionStatusMessage);
    return () => {
      socket.removeEventListener("message", handleMessage);
      socket.removeEventListener("message", handleDetectionStatusMessage);
    };
  }, [socket, deviceIds, addAlert]);

  const loadMoreAlerts = async () => {
    if (hasMoreAlerts) {
      const lastEvent = alerts?.length ? alerts[alerts.length - 1] : undefined;
      await doFetchEvents(lastEvent?.id);
    }
  };

  return {
    alerts,
    detectionStatusByDevice,
    addAlert,
    loadMoreAlerts,
    hasMoreAlerts,
    doFetchEvents,
    alertsLoading:
      eventsState.status === "not-executed" || eventsState.status === "loading",
    firstItemIndex,
    setIsFeedAtTop,
  };
};

import React from "react";

import { makeIdFromString } from "common/utils";
import styled from "styled-components";

interface Props {
  label: string;
  value: boolean;
  onChange: (nextVal: boolean) => void;
  displaySpaceBetween?: boolean;
}

export const Toggle: React.FC<Props> = ({
  label,
  onChange,
  value,
  displaySpaceBetween,
}) => {
  const id = makeIdFromString(label);

  return (
    <Wrapper spaceBetween={displaySpaceBetween}>
      <Label htmlFor={id}>{label}</Label>
      <ToggleWrapper>
        <ToggleInput
          id={id}
          type="checkbox"
          onChange={(e) => onChange(e.target.checked)}
          checked={value}
        />
        <ToggleKnob htmlFor={id} />
      </ToggleWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div<{ spaceBetween?: boolean }>`
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: ${({ spaceBetween }) =>
    spaceBetween ? "space-between" : "flex-start"};
  width: 100%;
`;

const Label = styled.label`
  font-size: 12px;
  font-weight: bold;
  padding-right: 16px;
`;

const ToggleWrapper = styled.div`
  position: relative;
  height: 24px;
`;

const ToggleKnob = styled.label`
  position: absolute;
  top: 0;
  left: 0;
  width: 48px;
  height: 24px;
  border-radius: 15px;
  background: var(--text-secondary);
  cursor: pointer;
  &::after {
    content: "";
    display: block;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    background: var(--text-primary);
    box-shadow: 3px 0px 6px #00000029;
    transition: 0.2s;
  }
`;

const ToggleInput = styled.input`
  opacity: 0;
  z-index: 1;
  border-radius: 15px;
  width: 48px;
  height: 24px;
  margin: 0;
  &:checked + ${ToggleKnob} {
    background: var(--asset);
    &::after {
      content: "";
      display: block;
      border-radius: 50%;
      margin-left: 25px;
      transition: 0.2s;
    }
  }
`;

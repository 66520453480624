import styled from "styled-components";

type FieldSize = "normal" | "large";

interface Props {
  variant?:
    | "primary"
    | "secondary"
    | "tertiary"
    | "quaternary"
    | "error"
    | "dark"
    | "success"
    | "transparent";
  withIcon?: boolean;
  withIconMiddle?: boolean;
  size?: FieldSize;
}

const buttonColor = {
  primary: "var(--active)",
  secondary: "var(--secondary)",
  tertiary: "var(--tertiary)",
  quaternary: "var(--info-highlighted)",
  error: "var(--alert)",
  dark: "var(--primary)",
  success: "var(--success)",
  transparent: "transparent",
};

export const Button = styled.button<Props>`
  display: ${({ withIcon }) => (withIcon ? "inline-flex" : "inline-block")};
  padding: ${({ withIcon }) => (withIcon ? "8px 16px" : "11px 16px 10px")};
  color: var(--text-primary);
  font-size: 14px;
  font-weight: 500;
  border-radius: 4px;
  min-height: ${({ withIcon }) => (withIcon ? "auto" : "40px")};
  background: ${({ variant }) =>
    (variant && buttonColor[variant]) || buttonColor["primary"]};
  box-shadow: none;
  border: 0;
  vertical-align: middle;

  &:hover {
    cursor: pointer;
  }

  ${({ withIconMiddle }) =>
    withIconMiddle &&
    `
      height: 42px;
  `}

  ${({ disabled }) =>
    disabled &&
    `
      color: #e8eaed60;
      background: var(--tertiary);

      &:hover {
        cursor: not-allowed;
      }
  `}

  ${({ size }) => (size === "large" ? "padding: 16px;" : "")}
`;

import { getToken } from "@asantech/common/react/localStorage";
import { useIntervalEffect } from "@react-hookz/web";
import { AxiosError } from "axios";
import { keepAlive } from "../../api";

const TOKEN_CHECK_INTERVAL = 1000 * 10;

export const useTokenKeepAlive = () => {
  const checkTokenValid = async () => {
    try {
      if (getToken()) await keepAlive();
    } catch (reason) {
      if (!(reason instanceof AxiosError) || reason?.response?.status !== 401) {
        console.log("Problem with connection occurred");
      }
    }
  };

  useIntervalEffect(checkTokenValid, TOKEN_CHECK_INTERVAL);
};

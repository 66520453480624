import { useIsDebugEnabled } from "@asantech/common/react/common/hooks/useIsDebugEnabled";
import { ReactComponent as ErrorIcon } from "assets/svgs/alert-error-icon.svg";
import React, { ReactNode } from "react";
import { useCameras } from "store/Cameras.context";
import styled from "styled-components";
import { CameraTileDebugInfo } from "./CameraTileDebugInfo";

interface Props {
  id: string;
  error?: Error;
}

export const Status: React.FC<Props> = ({ id, error }) => {
  const { detectionStatusByDevice } = useCameras();

  const status = detectionStatusByDevice[id];
  let statusText: ReactNode = status?.scanOn ? "scanning" : "live";

  const isDebugEnabled = useIsDebugEnabled();

  if (error) {
    statusText = (
      <ErrorIcon
        data-tooltip-id="tooltip"
        data-tooltip-content={error.message}
      />
    );
  }

  return (
    <Container active={!error} scanning={status?.scanOn || false}>
      {statusText}
      {isDebugEnabled && status && (
        <CameraTileDebugInfo deviceStatus={status}></CameraTileDebugInfo>
      )}
    </Container>
  );
};

const Container = styled.span<{ active: boolean; scanning: boolean }>`
  position: relative;
  display: inline-block;
  font-size: 12px;
  font-weight: 400;
  text-transform: uppercase;
  color: var(--text-primary-contrast);

  svg {
    width: 18px;
    height: 18px;
  }

  &::before {
    background: var(--${(props) => (props.scanning ? "asset" : "live")});
  }

  ${(props) =>
    (props.active || props.scanning) &&
    `
    &::before {
      position: absolute;
      top: 5px;
      left: -12px;
      content: "";
      width: 8px;
      height: 8px;
      border-radius: 100%;
    }
  `}
`;

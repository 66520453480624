export const makeIdFromString = (string: string) =>
  string.split(" ").join("-").toLowerCase();

export const groupByKey = <T>(array: T[], key: keyof T): Record<string, T[]> =>
  (array || []).reduce((prev, cur) => {
    const index = cur[key] + "";
    let group = prev[index];
    if (!group) {
      group = [];
      prev[index] = group;
    }
    group.push(cur);
    return prev;
  }, {} as Record<string, T[]>);

export const noop = () => {
  //no operation function
};

export const getFetchErrorMsg = (resource: string) =>
  `There was a problem while loading ${resource}. Please refresh your browser’s window and if the issue still occurs contact our tech support.`;

export const compose =
  <T>(...funcs: Array<(val: T) => T>) =>
  (val: T) =>
    funcs.reduceRight((res, fn) => fn(res), val);

export const getLastIfExists = <T>(array?: T[]) =>
  array && array.length ? array[array.length - 1] : undefined;

export const subtractArray = <T>(arr1: T[], arr2: T[]) =>
  arr1.filter((n) => !arr2.includes(n));

export const intersectArray = <T>(arr1: T[], arr2: T[]) =>
  arr1.filter((n) => arr2.includes(n));

export const isEmpty = (arr?: unknown[]) => !arr || arr.length === 0;

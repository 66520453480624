import deepEqual from "fast-deep-equal/es6";
import { Fragment, useCallback } from "react";
import { SelectableGroup } from "react-selectable-fast";
import styled from "styled-components";
import { padZero } from "../../../../backend/src/utils/timeUtils";
import { calendarDays } from "./calendarPeriods";
import { Cell, SelectedCell } from "./Cell";
import { PeriodByWeekDay } from "./types";
import { splitTime } from "./utils";

interface Props {
  onChange: (activePeriods: PeriodByWeekDay[]) => void;
  activePeriods: PeriodByWeekDay[];
  allPeriods: PeriodByWeekDay[];
}

export function ScheduleCalendar(props: Props) {
  const { onChange, activePeriods, allPeriods } = props;

  const doUpdateSelection = useCallback(
    (selectedItems: SelectedCell[]) => {
      const selected = selectedItems.map((value) => value.props.cellPeriod);
      onChange(selected);
    },
    [onChange]
  );

  const getSelectedPeriod = useCallback(
    (period: PeriodByWeekDay) =>
      activePeriods.find(({ dayOfWeek, from, to }) =>
        deepEqual(period, { dayOfWeek, from, to })
      ),
    [activePeriods]
  );

  const formatCalendarAxisHour = useCallback((period: PeriodByWeekDay) => {
    const [hours, minutes] = splitTime(period.from);
    const date = new Date();
    date.setUTCHours(parseInt(hours));
    date.setUTCMinutes(parseInt(minutes));

    return [padZero(date.getHours()), padZero(date.getMinutes())].join(":");
  }, []);

  return (
    <SelectableGroup
      enableDeselect
      onSelectionFinish={doUpdateSelection}
      deselectOnEsc={false}
    >
      <Frame>
        <CalendarHeader />
        {calendarDays.map((d) => (
          <CalendarHeader key={d}>
            <strong>{d}</strong>
          </CalendarHeader>
        ))}

        {allPeriods.map((period, idx) => {
          const selectedPeriod = getSelectedPeriod(period);

          return (
            <Fragment key={idx}>
              {!(idx % 7) && <Hour>{formatCalendarAxisHour(period)}</Hour>}
              <Cell
                cellPeriod={selectedPeriod || period}
                isSelected={!!selectedPeriod}
              />
            </Fragment>
          );
        })}
      </Frame>
    </SelectableGroup>
  );
}

const Frame = styled.div`
  width: 400px;
  background-color: #202124;
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-gap: -1px;
  --border: 1px solid #3c3e41;
  --border-active: 1px solid #202124;
`;

const CalendarHeader = styled.div`
  cursor: pointer;
  padding: 5px;
  border-bottom: var(--border);
  text-align: center;
  position: sticky;
  top: 0px;
  z-index: 2;
  background-color: #202124;
  border: 2px solid #202124;
`;

const Hour = styled.div`
  font-size: small;
  text-align: center;
  border-right: var(--border);
  user-select: none;
`;

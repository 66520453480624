import {
  Coordinate,
  CoordinatesByDetectionClass,
} from "../../../../../backend/src/events/types";
import { useMemo } from "react";

export const useAggregateDetections = (
  coordinates: CoordinatesByDetectionClass,
  videoHeight: number,
  videoWidth: number
) => {
  return useMemo(
    () =>
      Object.entries(coordinates || []).reduce(
        (acc, [detectionClass, items]) => [
          ...acc,
          ...items.map((item) => ({
            detectionClass,
            ...item,
            ...(item.point
              ? {
                  point: [
                    item.point[0] * videoWidth,
                    item.point[1] * videoHeight,
                  ] as Coordinate,
                }
              : {}),
            ...(item.bbox
              ? {
                  bbox: [
                    [
                      item.bbox[0][0] * videoWidth,
                      item.bbox[0][1] * videoHeight,
                    ],
                    [
                      item.bbox[1][0] * videoWidth,
                      item.bbox[1][1] * videoHeight,
                    ],
                  ] as [Coordinate, Coordinate],
                }
              : {}),
          })),
        ],
        [] as AggregatedDetection[]
      ),
    [coordinates, videoHeight, videoWidth]
  );
};

export type AggregatedDetection = {
  detectionClass: string;
  id: number;
  point?: Coordinate;
  bbox?: [Coordinate, Coordinate];
};

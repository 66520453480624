import { Button } from "components";
import React, { ReactNode } from "react";
import styled from "styled-components";

interface Props {
  active: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  children?: ReactNode;
}

export const ToggleButton: React.FC<Props> = ({
  active,
  children,
  onClick,
}) => {
  return (
    <Wrapper active={active}>
      <Button
        variant={active ? "primary" : "tertiary"}
        onClick={onClick}
        data-testid="toggleBtn"
      >
        {children}
      </Button>
    </Wrapper>
  );
};

const Wrapper = styled.span<{ active: boolean }>`
  position: relative;
  display: inline-block;

  &:before {
    position: absolute;
    bottom: -8px;
    content: "";
    height: 2px;
    width: 100%;
    background: var(--text-primary);
    opacity: ${({ active }) => (active ? 1 : 0)};
  }
`;

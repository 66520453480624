import React from "react";

import { ReactComponent as ArrowIcon } from "assets/svgs/arrow-icon.svg";
import { useDropdown } from "common/hooks";
import styled from "styled-components";

export type DropdownValue = string | number;

interface DropdownOption {
  label: string;
  value: DropdownValue;
}

export interface DropdownProps {
  onChange: (value: DropdownValue) => void;
  value: DropdownValue;
  options: DropdownOption[];
}

export const Dropdown: React.FC<DropdownProps> = ({
  onChange,
  value,
  options,
}) => {
  const { open, isOpen, ref, handleChange } = useDropdown({ onChange });
  const chosenOption = options.find((option) => option.value === value);
  const chosenOptionLabel = chosenOption?.label || "";

  return (
    <Wrapper ref={ref}>
      <Input onClick={open} value={chosenOptionLabel} readOnly />
      <ArrowIcon onClick={open} className="icon" />
      {isOpen && (
        <List>
          {options.map(({ label, value }) => (
            <ListItem key={value}>
              <Option onClick={handleChange(value)}>{label}</Option>
            </ListItem>
          ))}
        </List>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  width: 100%;

  svg.icon {
    position: absolute;
    top: 15px;
    right: 15px;
    transform: rotate(90deg);
    cursor: pointer;
  }
`;

const Input = styled.input`
  background: var(--secondary);
  width: 100%;
  box-shadow: none;
  border-radius: 4px;
  height: 40px;
  padding: 11px 16px 10px;
  border: 1px solid transparent;
  box-shadow: none;
  color: var(--text-primary);

  &:hover {
    cursor: pointer;
  }
`;

const List = styled.ul`
  position: absolute;
  top: 44px;
  background: var(--secondary);
  width: 100%;
  box-shadow: none;
  border-radius: 4px;
  list-style: none;
  padding: 0;
  margin: 0;
`;

const ListItem = styled.li`
  border-bottom: 1px solid var(--tertiary);

  &:first-of-type {
    button {
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }
  }

  &:last-of-type {
    border-bottom: 0;

    button {
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }
`;

const Option = styled.button`
  border: 0;
  box-shadow: none;
  background: transparent;
  font-size: 14px;
  font-weight: 400;
  color: var(--text-secondary);
  padding: 11px 16px 10px;
  width: 100%;
  text-align: left;

  &:hover {
    cursor: pointer;
    background: #2d3035;
  }
`;

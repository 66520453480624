import { Pages } from "./pages";

export const getCameraLinks = (deviceId: string) => [
  {
    title: "Area scanner settings",
    to: Pages.CameraSettings.replace(":deviceId", deviceId),
  },
  {
    title: "Event log",
    to: Pages.Camera.replace(":deviceId", deviceId),
  },
];

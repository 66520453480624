import { useRef, useState, useLayoutEffect } from "react";

// based on https://css-tricks.com/making-sense-of-react-spring/
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useHeight({ on = true } = {} as any) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const ref = useRef<HTMLElement>();
  const [height, set] = useState(0);
  const heightRef = useRef(height);
  const [ro] = useState(
    () =>
      new ResizeObserver(() => {
        if (ref.current && heightRef.current !== ref.current.offsetHeight) {
          heightRef.current = ref.current.offsetHeight;
          set(ref.current.offsetHeight);
        }
      })
  );
  useLayoutEffect(() => {
    if (on && ref.current) {
      set(ref.current.offsetHeight);
      ro.observe(ref.current, {});
    }
    return () => ro.disconnect();
  }, [on, ro]);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return [ref, height as any];
}

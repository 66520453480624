import { useMemo, useState } from "react";
import { Camera, Location } from "../../models/cameras";

export type ActiveLocationsStore = {
  locations: Location[];
  devices: Camera[];
  removeLocation: (deviceGroupId: string) => void;
  addLocation: (location: Location) => void;
};

export const useActiveLocations = () => {
  const [locations, setLocations] = useState<Location[]>([]);

  const removeLocation = (deviceGroupId: string) =>
    setLocations((prev) => prev.filter((el) => el.id !== deviceGroupId));
  const addLocation = (location: Location) =>
    setLocations((prev) => [...prev, location]);

  const devices = useMemo(() => {
    return locations.reduce(
      (acc, currentValue) => [...acc, ...currentValue.cameras],
      [] as Camera[]
    );
  }, [locations]);

  return {
    locations,
    devices,
    addLocation,
    removeLocation,
  };
};

import { FieldPath, FieldValues, UseFormSetError } from "react-hook-form";
import { AxiosError, handleError } from "../api/client";

export type ValidationError = {
  path: string;
  message: string;
};

export const NAME_GENERAL_MAX_LENGTH = 100;

export const maxLengthValidator = (maxLength = NAME_GENERAL_MAX_LENGTH) => ({
  maxLength: {
    value: maxLength,
    message: `Max length of ${maxLength} characters exceeded`,
  },
});

export const phoneNumberValidator = () => ({
  pattern: {
    value: /^[+]\d{1,3}[0-9]{1,14}$/i, // format E.164
    message:
      "Invalid phone number. Valid number is 1-14 digits long with an additional 1-3 digit country code. Eg. +35801123123123",
  },
});

export const arrMinLengthValidator = <T extends Array<unknown>>(
  minLength = 1
) => ({
  validate: {
    arrMinLength: (value?: T) =>
      (value || []).length >= minLength ||
      `Should containt at least ${minLength} item${minLength > 1 ? "s" : ""}`,
  },
});

export const arrToDropdownOptions = <T>(arr: T[]) =>
  arr.map((v) => ({ value: v, label: v }));

/**
 * Attempts to display validation errors using the setError function
 * @returns False if the error wasn't a validation error, otherwise True
 */
export const setInputValidationErrors = <T extends FieldValues>(
  axiosError: AxiosError<ValidationError & { errors?: ValidationError[] }>,
  setError: UseFormSetError<T>
) => {
  const message = axiosError.response?.data?.message || "";
  if (message !== "Input validation error") return false;

  const errors = (axiosError.response?.data?.errors || []) as ValidationError[];

  errors.forEach((error) => {
    const field = error.path.replace(".body.", "") as FieldPath<T>;
    setError(field, {
      type: "server",
      message: error.message,
    });
  });
  return true;
};

export const handleFormSubmitError = <T extends FieldValues>(
  error: unknown,
  setError: UseFormSetError<T>,
  errorMessage?: string
) => {
  if (
    !setInputValidationErrors(
      error as AxiosError<ValidationError>,
      setError as UseFormSetError<FieldValues>
    )
  ) {
    handleError(error, errorMessage || (error as Error).message);
  }
};

import { Pages } from "common/pages";
import { History } from "history";
import { CameraView } from "pages/camera";
import { CamerasView } from "pages/cameras";
import React from "react";
import { Redirect, Route, Router, Switch } from "react-router-dom";
import { WebSocketProvider } from "store/Websocket.context";
import { useAuth } from "../common/hooks/use-auth";
import { AlertRulesView } from "../pages/alert-rules";
import { LoginView } from "../pages/login";
import { AlertsContextProvider } from "../store/Alerts.context";
import { CamerasContextProvider } from "../store/Cameras.context";

interface Props {
  history: History<unknown>;
}

export const AppRouter: React.FC<Props> = ({ history }) => {
  const { isAuth } = useAuth();

  return (
    <Router history={history}>
      {!isAuth && <Redirect to={Pages.Login} />}
      <Switch>
        <Route path={Pages.Login}>
          <LoginView />
        </Route>
        <WebSocketProvider>
          <CamerasContextProvider>
            <Route path={Pages.AlertRules}>
              <AlertsContextProvider>
                <AlertRulesView />
              </AlertsContextProvider>
            </Route>
            <Route path={Pages.Home} exact>
              <CamerasView />
            </Route>
            <Route path={[Pages.Camera, Pages.CameraSettings]}>
              <CameraView />
            </Route>
          </CamerasContextProvider>
        </WebSocketProvider>
      </Switch>
    </Router>
  );
};

import { useToggle } from "@react-hookz/web";
import { Button } from "components/Button";
import { Tag } from "components/Tag";
import React, { useCallback } from "react";
import styled from "styled-components";
import { AlertRule } from "../../../../backend/src/alerts/types";
import { useAlerts } from "../../store/Alerts.context";

interface Props {
  rule: AlertRule;
  isSelected: boolean;
  isFormDirty: boolean;
  onCardSelect: (id: string) => void;
  onToggleDiscardDialog: (show: boolean) => void;
  onToggleEditMode: (show: boolean) => void;
}

export const RuleCard: React.FC<Props> = ({
  rule: { id, name, message, deviceGroupIds, recipients },
  isSelected,
  isFormDirty,
  onCardSelect,
  onToggleDiscardDialog,
  onToggleEditMode,
}) => {
  const [showAllRecipients, toggleShowAllRecipients] = useToggle(false);
  const { deviceGroupsGroupNames } = useAlerts();

  const handleCardSelect = useCallback(() => {
    if (isFormDirty) return onToggleDiscardDialog(true);
    onToggleEditMode(false);
    onCardSelect(id);
  }, [id, isFormDirty, onCardSelect, onToggleDiscardDialog, onToggleEditMode]);
  const handleToggleShowAllRecipients = useCallback(
    () => toggleShowAllRecipients(),
    [toggleShowAllRecipients]
  );

  return (
    <Wrapper selected={isSelected}>
      <Header onClick={handleCardSelect}>
        <span>{name}</span>
        <div>
          {(deviceGroupIds || []).map((g) => (
            <Tag
              key={g}
              id={g}
              name={deviceGroupsGroupNames[g] || ""}
              size="large"
              variant="secondaryDark"
            />
          ))}
        </div>
      </Header>
      <Content>
        <MsgColumn>
          <SubText>Content of the message</SubText>
          {message.split("\n").map((text, i) => (
            <Text key={i}>{text || ""}</Text>
          ))}
        </MsgColumn>
        <RecipientsColumn>
          <SubText>Recipients</SubText>
          {recipients
            .slice(0, showAllRecipients ? recipients.length : 3)
            .map((r, i) => (
              <Recipient key={i}>{r}</Recipient>
            ))}
          {recipients.length > 3 && (
            <AllRecipientsBtn onClick={handleToggleShowAllRecipients}>
              {showAllRecipients ? "Hide" : `${recipients.length - 3} more`}
            </AllRecipientsBtn>
          )}
        </RecipientsColumn>
      </Content>
    </Wrapper>
  );
};

const Wrapper = styled.div<{ selected?: boolean }>`
  background: ${({ selected }) => (selected ? "#313740" : "var(--secondary)")};
  border-radius: 4px;
  margin: 0 16px 32px;
  color: #fff;
  min-width: 900px;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  padding: 14px 36px;
  justify-content: space-between;
  box-shadow: 0px 3px 6px #00000029;
  font-size: 16px;
  cursor: pointer;
`;

const Content = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 36px;
`;

const Text = styled.p`
  font-size: 14px;
  font-weight: bold;
  color: var(--text-primary);
  min-height: 24px;
`;

const SubText = styled.p`
  font-size: 12px;
  padding-bottom: 12px;
`;

const Recipient = styled(Text)`
  line-height: 36px;
`;

const AllRecipientsBtn = styled(Button)`
  background: none;
  color: #466e96;
  padding: 0;
`;

const Column = styled.div`
  flex: 1;
  padding-right: 20px;
`;

const MsgColumn = styled(Column)`
  flex-basis: 0.285;
`;

const RecipientsColumn = styled(Column)`
  flex-basis: 0.22;
`;

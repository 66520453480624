import { useVideoMeasure } from "@asantech/common/react/CamPreview/KonvaVideoWrapper";
import useFullscreen from "@asantech/common/react/common/hooks/useFullscreen";
import { LiveVideo, Resolution } from "@asantech/common/react/LiveVideo";
import { useToggle } from "@react-hookz/web";
import { handleError } from "api/client";
import { getLiveStream } from "api/devices";
import { ReactComponent as ExpandIcon } from "assets/svgs/expand-icon.svg";
import { ReactComponent as FencingIcon } from "assets/svgs/fencing-icon.svg";
import { ReactComponent as FullIcon } from "assets/svgs/full-icon.svg";
import { ReactComponent as ShrinkIcon } from "assets/svgs/shrink-icon.svg";
import { Pages } from "common/pages";
import { useWebSocketsVideoStreams } from "config";
import React, { useCallback, useMemo, useRef, useState } from "react";
import styled from "styled-components";
import { CameraSettings } from "../../../../../backend/src/devices/types";
import { CoordinatesByDetectionClass } from "../../../../../backend/src/events/types";
import { useNavigate } from "../../../common/hooks/use-navigate";
import { AreaEditor } from "../../camera/CamPreview/AreaEditor";
import { Status } from "../../camera/CamPreview/Status";

interface Props {
  id: string;
  title: string;
  expanded: boolean;
  onExpandClick: () => void;
  camVideoPath: string;
  cameraSettings?: CameraSettings;
  detectedObjects?: CoordinatesByDetectionClass;
}

export const CameraTile: React.FC<Props> = ({
  id,
  title,
  expanded,
  onExpandClick,
  cameraSettings,
  detectedObjects = {},
}) => {
  const [error, setError] = useState<Error>();

  const ref = useRef(null);
  const [show, toggleFullScreen] = useToggle(false);
  const isFullScreen = useFullscreen(ref, show, {
    onClose: () => toggleFullScreen(false),
  });

  const openCameraSettings = useNavigate(
    Pages.CameraSettings.replace(":deviceId", id)
  );
  const areaToScan = cameraSettings ? cameraSettings.areaToScan : [];
  const handleStreamError = useCallback((error: Error) => {
    setError(error);
    handleError(error);
  }, []);

  const { setAspectRatio, ...videoMeasure } = useVideoMeasure();
  const badges = useMemo(
    () =>
      Object.keys(detectedObjects)
        .filter((key) => detectedObjects[key]?.length)
        .map((key) => `${key} (${detectedObjects[key]?.length})`),
    [detectedObjects]
  );

  return (
    <PlaceholderWrapper>
      <Head>
        <Title title={title}>{title}</Title>
        <Badges>
          {badges.map((text) => (
            <Badge key={text}>{text}</Badge>
          ))}
        </Badges>
        <Status id={id} error={error} />
      </Head>
      <PreviewWrapper ref={ref}>
        <AreaEditor
          drawingActive={false}
          cornersInitial={areaToScan}
          videoMeasure={videoMeasure}
          deviceId={id}
        >
          <UVPStreamWrapper>
            <LiveVideo
              useAsanPlayer={useWebSocketsVideoStreams}
              cameraId={id}
              resolution={Resolution.LOW}
              onError={handleStreamError}
              setAspectRatio={setAspectRatio}
              getLiveStream={getLiveStream}
            />
          </UVPStreamWrapper>
        </AreaEditor>
      </PreviewWrapper>
      <ButtonsWrapper>
        {!isFullScreen && (
          <>
            <Button onClick={openCameraSettings}>
              <FencingIcon />
            </Button>
            <Button onClick={onExpandClick}>
              {expanded ? <ShrinkIcon /> : <ExpandIcon />}
            </Button>
          </>
        )}
        <Button onClick={toggleFullScreen}>
          <FullIcon />
        </Button>
      </ButtonsWrapper>
    </PlaceholderWrapper>
  );
};

const PlaceholderWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  background: #191a1c;
  overflow: hidden;
  width: 100%;
  height: 100%;
`;

const Head = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 12px;
  height: 35px;
  flex-shrink: 0;
`;

const Title = styled.p`
  font-size: 13px;
  font-weight: bold;
  color: var(--text-primary-contrast);
  white-space: nowrap;
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ButtonsWrapper = styled.div`
  position: absolute;
  z-index: 2;
  bottom: 0;
  right: 0;
  display: inline-block;
  background: #20212466;
  border-radius: 4px 0px 0px 4px;
`;

const Button = styled.button`
  border: 0;
  outline: 0;
  box-shadow: none;
  background: transparent;
  padding: 0;
  margin: 0;
  height: 40px;
  width: 40px;

  &:hover {
    cursor: pointer;
  }
`;

const PreviewWrapper = styled.div`
  position: relative;
  height: 100%;
  overflow: hidden;
`;

const UVPStreamWrapper = styled.div`
  width: 100%;
  height: 100%;
`;

const Badges = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  padding: 0 24px 0 6px;
`;

const Badge = styled.div`
  display: inline-block;
  padding: 3px 10px;
  vertical-align: middle;
  background: rgba(255, 187, 12, 0.05);
  border-radius: 23px;
  color: #ffbb0c;
  font-size: 12px;
  text-transform: capitalize;
  white-space: nowrap;

  & + & {
    margin-left: 6px;
  }
`;

import { ImagesCarousel } from "@asantech/common/react/ImagesCarousel";
import { ZoomableImage } from "@asantech/common/react/ZoomableImage";
import { useMeasure } from "@react-hookz/web";
import { Fragment, useMemo } from "react";
import styled from "styled-components";
import { EventDetectionsResponse } from "../../../../../backend/src/events/types";
import { getImageUrl } from "../../../api/endpoints";
import { AlertTooltip } from "../../../components/AlertTooltip";
import { DetectionsLayer } from "../../camera/CamPreview/DetectionsLayer";

interface Props {
  selectedImage: number;
  setSelectedImage: (fn: (prev: number) => number) => void;
  detections: EventDetectionsResponse[];
}

export const DetectionOverlay = (props: EventDetectionsResponse) => {
  const [measurements, ref] = useMeasure<HTMLDivElement>();

  return (
    <StageWrapper ref={ref}>
      {!!measurements?.width && !!measurements?.height && props.coordinates && (
        <DetectionsLayer
          coordinates={props.coordinates}
          videoHeight={measurements.height}
          videoWidth={measurements.width}
        />
      )}
    </StageWrapper>
  );
};

export const DetectionsDisplay = (props: Props) => {
  const { selectedImage, setSelectedImage, detections } = props;

  const countClassesForSelected = useMemo(() => {
    const selectedDetection = detections[selectedImage];
    if (!selectedDetection) {
      return {};
    }
    return Object.entries(selectedDetection.coordinates).reduce(
      (acc, [className, detections]) => {
        const count = Object.values(detections).length;
        if (count) {
          acc[className] = Object.values(detections).length;
        }

        return acc;
      },
      {} as Record<string, number>
    );
  }, [detections, selectedImage]);

  return (
    <ImageCarouselWrapper>
      {Object.entries(countClassesForSelected).map(([className, count]) => (
        <Fragment key={className}>
          <AlertTooltip>
            {className} ({count})
          </AlertTooltip>
          &nbsp;
        </Fragment>
      ))}

      <ImagesCarousel
        selectedImage={selectedImage}
        setSelectedImage={setSelectedImage}
      >
        {detections.map((detectionsData) => (
          <ZoomableImage
            key={detectionsData.imageId}
            src={getImageUrl(detectionsData.imageId)}
            height={190}
            alt={"Detection image"}
          >
            <DetectionOverlay {...detectionsData} />
          </ZoomableImage>
        ))}
      </ImagesCarousel>
    </ImageCarouselWrapper>
  );
};

const ImageCarouselWrapper = styled.div`
  cursor: auto;
  && {
    .bottom-bar,
    .btn {
      background: transparent;
    }
  }
`;
const StageWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

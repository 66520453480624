import React from "react";
import {
  FieldError,
  FieldValues,
  Path,
  RegisterOptions,
  UseFormRegister,
} from "react-hook-form";
import styled from "styled-components";

type FieldVariant = "tertiary" | "secondary" | "primary";

type FieldSize = "normal" | "large";

type Props<TFieldValues extends FieldValues> = {
  name: Path<TFieldValues>;
  placeholder?: string;
  register: UseFormRegister<TFieldValues>;
  required: boolean;
  errors?: FieldError;
  variant?: FieldVariant;
  className?: string;
  registerRules?: RegisterOptions;
  onChange?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  disabled?: boolean;
  size?: FieldSize;
};

export function TextArea<T extends FieldValues>({
  name,
  placeholder,
  register,
  required,
  errors,
  variant = "tertiary",
  size = "normal",
  registerRules = {},
  disabled,
}: Props<T>) {
  return (
    <StyledTextArea
      {...register(name, {
        required: { value: required, message: "Field is required" },
        ...registerRules,
      })}
      disabled={disabled}
      variant={variant}
      placeholder={placeholder}
      fieldSize={size}
      invalid={!!errors}
    />
  );
}

export const StyledTextArea = styled.textarea<{
  invalid: boolean;
  variant: FieldVariant;
  fieldSize: FieldSize;
}>`
  display: inline-block;
  width: 100%;
  background-color: ${({ variant }) => `var(--${variant})`};
  border-radius: 4px;
  min-height: 40px;
  height: 120px;
  padding: 10px 16px 7px;
  border: 1px solid transparent;
  box-shadow: none;
  color: var(--text-primary);
  border-color: ${({ invalid }) => (invalid ? "var(--live)" : "transparent")};
  resize: none;

  ${({ fieldSize }) => (fieldSize === "large" ? "padding: 16px;" : "")}

  ${({ disabled }) =>
    disabled
      ? `
    background-color: var(--secondary-variant);
    opacity: 0.4;
  `
      : ""}

  &::placeholder {
    color: var(--text-secondary);
    font-style: italic;
  }
`;

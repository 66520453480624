import { useAsync, useList, useMap } from "@react-hookz/web";
import { useCallback } from "react";
import { Device } from "../../../../backend/src/devices/types";
import { handleError } from "../../api/client";
import { getDeviceGroups, getDevices } from "../../api/devices";
import { getFetchErrorMsg } from "../../common/utils";
import { Camera, Location } from "../../models/cameras";

const deviceReducerFn = (
  result: Record<string, Camera>,
  item: Device
): Record<string, Camera> => {
  if (!item.isCamera) {
    return result;
  }

  return {
    ...result,
    [item.deviceId]: {
      name: item.deviceName,
      id: item.deviceId,
      // TODO: support for real video source
      videoPath: `/videos/cam_preview_${
        parseInt(item.deviceId) % 2 ? 2 : 3
      }.mp4`,
    },
  };
};

export type DeviceStore = {
  isLoading: boolean;
  deviceGroupsById: Map<string, Location>;
  activeCameras: string[];
  addActiveCamera: (...items: string[]) => void;
  camerasById: Map<string, Camera>;
  updateCamera: (deviceId: string, newItem: Camera) => void;
  removeActiveCamera: (id: string) => void;
};

export const useDevices = () => {
  const deviceGroupsById = useMap<string, Location>([]);
  const setDeviceGroupsById = useCallback(
    (values: Location[]) => {
      deviceGroupsById.clear();
      values.forEach((value) => deviceGroupsById.set(value.id, value));
    },
    [deviceGroupsById]
  );

  const camerasById = useMap<string, Camera>([]);
  const setCameras = useCallback(
    (values: Camera[]) => {
      camerasById.clear();
      values.forEach((value) => camerasById.set(value.id, value));
    },
    [camerasById]
  );

  const [devicesWithGroupsState, { execute: doFetchDevicesWithGroups }] =
    useAsync(async () => {
      try {
        const [groups, devices] = await Promise.all([
          getDeviceGroups(),
          getDevices(),
        ]);

        const devicesById = devices.reduce(deviceReducerFn, {});
        const locations = groups.map((group) => ({
          id: group.deviceGroupId,
          name: group.deviceGroupName,
          cameras: group.devices
            .map((deviceId) => deviceId.toString())
            .filter((deviceId) => devicesById[deviceId])
            .map((deviceId) => {
              const device = devicesById[deviceId];
              if (!device) {
                throw new Error(
                  "Unexpected data from backend, device in group not listed in device listing."
                );
              }
              device.deviceGroupIds = device.deviceGroupIds || [];
              device.deviceGroupIds.push(group.deviceGroupId);
              return device;
            }),
        }));

        setCameras(Object.values(devicesById));
        setDeviceGroupsById(locations);
      } catch (error) {
        handleError(error, getFetchErrorMsg("devices"));
      }
    });

  const [
    activeCameras,
    { push: addActiveCamera, filter: filterActiveCameras },
  ] = useList<string>([]);

  const removeActiveCamera = (id: string) =>
    filterActiveCameras((cameraId) => cameraId !== id);

  return {
    deviceGroupsById,
    activeCameras,
    updateCamera: camerasById.set,
    addActiveCamera,
    removeActiveCamera,
    camerasById,
    isLoading:
      devicesWithGroupsState.status === "not-executed" ||
      devicesWithGroupsState.status === "loading",
    doFetchDevicesWithGroups,
  };
};

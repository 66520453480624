import { TopBar } from "components";
import React, { ReactNode } from "react";
import styled from "styled-components";

interface Props {
  barContent: JSX.Element;
  sideContent: JSX.Element;
  navContent?: JSX.Element;
  children?: ReactNode;
}

export const DashboardLayout: React.FC<Props> = ({
  children,
  barContent,
  sideContent,
  navContent = null,
}) => {
  return (
    <>
      <TopBar />
      <Wrapper>
        <Main>
          <Bar>{barContent}</Bar>
          {children}
        </Main>
        <SideWrapper>
          {navContent}
          <Side>{sideContent}</Side>
        </SideWrapper>
      </Wrapper>
    </>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  height: calc(100% - 56px);
`;

const Main = styled.div`
  display: flex;
  flex-direction: column;
  background: var(--primary);
  width: calc(100% - 440px);
`;

const SideWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const Side = styled.div`
  flex: 1;
  width: 440px;
  background: var(--secondary);
  box-shadow: 0px 3px 6px #00000029;
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none;
  }
`;

const Bar = styled.div`
  display: flex;
  align-items: center;
  height: 56px;
  width: 100%;
  background: var(--secondary);
  padding: 8px 16px;
`;

import { formatTime } from "@asantech/common/dateUtils";

export interface OccurrencesProps {
  occurrencesCount: number;
  firstSeen: string;
}

export const Occurrences = (props: OccurrencesProps) => {
  const { occurrencesCount, firstSeen } = props;
  if (occurrencesCount < 2) return <span>&nbsp;</span>;

  return (
    <span
      title={`First seen ${formatTime(
        firstSeen
      )} for a total of ${occurrencesCount} times.`}
    >
      +{occurrencesCount}
    </span>
  );
};

import React from "react";

import styled from "styled-components";

interface Props {
  label: string;
  onChange: (nextVal: boolean) => void;
  checked: boolean;
}

export const Checkbox: React.FC<Props> = ({ label, onChange, checked }) => {
  return (
    <Container>
      {label}
      <Input
        type="checkbox"
        checked={checked}
        onChange={() => onChange(!checked)}
      />
      <Checkmark />
    </Container>
  );
};

const Checkmark = styled.span`
  position: absolute;
  top: 2.5px;
  left: 0;
  height: 16px;
  width: 16px;
  border-radius: 4px;
  border: 1px solid transparent;
  border-color: #aaaaaa;

  &:after {
    content: "";
    position: absolute;
    display: none;
    left: 5px;
    top: 1.5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`;

const Input = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;

  &:checked ~ ${Checkmark} {
    background-color: var(--info);
    border-color: transparent;
  }

  &:checked ~ ${Checkmark}:after {
    display: block;
  }
`;

const Container = styled.label`
  display: block;
  position: relative;
  padding-left: 24px;
  cursor: pointer;
  font-size: 14px;
  color: var(--text-primary);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

import { ReactComponent as CloseIcon } from "assets/svgs/close-icon.svg";
import {
  arrMinLengthValidator,
  maxLengthValidator,
  phoneNumberValidator,
} from "common/formUtils";
import { Button } from "components/Button";
import { FieldRow } from "components/FieldRow";
import { Tag } from "components/Tag";
import { TagsDropdown } from "components/TagsDropdown";
import { TextArea } from "components/TextArea";
import { TextField } from "components/TextField";
import { useCallback, useEffect } from "react";
import {
  Controller,
  ControllerRenderProps,
  FieldError,
  useFieldArray,
  UseFormReturn,
} from "react-hook-form";
import styled from "styled-components";
import { AlertRule } from "../../../../backend/src/alerts/types";
import { useAlerts } from "../../store/Alerts.context";

export type AlertRuleForm = Omit<AlertRule, "recipients"> & {
  recipients: { phoneNumber: string }[];
};

type Props = {
  form: UseFormReturn<AlertRuleForm>;
  isEditing: boolean;
};

export const RuleForm = ({ isEditing, form }: Props) => {
  const {
    deviceGroupsListLoading,
    deviceGroupsDropdownOptions,
    deviceGroupsGroupNames,
  } = useAlerts();
  const {
    control,
    register,
    formState: { errors },
    setError,
    clearErrors,
  } = form;
  const {
    fields: recipients,
    append: addRecipient,
    remove: removeRecipient,
  } = useFieldArray<AlertRuleForm>({
    control,
    name: "recipients",
  });

  const handleAddRecipient = useCallback(() => {
    addRecipient({ phoneNumber: "" });
  }, [addRecipient]);
  const handleRemoveFromArrayField = useCallback(
    (
        value: string,
        field: ControllerRenderProps<AlertRuleForm, "deviceGroupIds">
      ) =>
      () => {
        const result = (field.value || []).filter((v: string) => v !== value);
        field.onChange(result.length ? result : null);
      },
    []
  );

  useEffect(() => {
    recipients.length
      ? clearErrors("recipients")
      : setError("recipients", {
          type: "required",
          message: "At least one recipient is required",
        });
  }, [recipients, clearErrors, setError]);

  return (
    <Form>
      <FieldRow label="Name*" error={errors.name}>
        <TextField
          register={register}
          name="name"
          registerRules={maxLengthValidator()}
          required={true}
          variant="secondary"
          errors={errors.name}
          disabled={!isEditing}
          size="large"
        />
      </FieldRow>
      <FieldRow label="Content of the message*" error={errors.message}>
        <TextArea
          register={register}
          name="message"
          registerRules={maxLengthValidator()}
          required={true}
          variant="secondary"
          errors={errors.message}
          disabled={!isEditing}
          size="large"
        />
      </FieldRow>
      <FieldRow label="Recipients*">
        <TagsWrapper>
          {recipients.map((field, index) =>
            isEditing ? (
              <FieldRow
                key={field.id}
                error={errors.recipients?.[index]?.phoneNumber}
              >
                <Row>
                  <RemoveBtn
                    type="button"
                    onClick={() => removeRecipient(index)}
                    disabled={!isEditing}
                  >
                    <CloseIcon />
                  </RemoveBtn>
                  <TextField
                    register={register}
                    name={`recipients.${index}.phoneNumber`}
                    defaultValue={field.phoneNumber || ""}
                    registerRules={phoneNumberValidator()}
                    required={true}
                    variant="secondary"
                    size="large"
                    errors={errors.recipients?.[index]?.phoneNumber}
                    disabled={!isEditing}
                  />
                </Row>
              </FieldRow>
            ) : (
              <Tag
                key={field.id}
                id={field.phoneNumber}
                size="large"
                disabled={true}
              />
            )
          )}
        </TagsWrapper>
      </FieldRow>
      {isEditing && (
        <>
          <Button type="button" size="large" onClick={handleAddRecipient}>
            Add recipient
          </Button>
          <FieldRow error={errors.recipients as unknown as FieldError} />
        </>
      )}
      <FieldRow
        label="Groups of devices*"
        error={errors.deviceGroupIds as FieldError | undefined}
      >
        <Controller
          name="deviceGroupIds"
          control={control}
          rules={arrMinLengthValidator()}
          render={({ field }) => (
            <>
              <TagsWrapper>
                {(field.value || []).map((g) => (
                  <Tag
                    key={g}
                    id={g}
                    name={deviceGroupsGroupNames[g] || ""}
                    size="large"
                    disabled={!isEditing}
                    onRemove={handleRemoveFromArrayField(g, field)}
                  />
                ))}
              </TagsWrapper>
              {isEditing && (
                <TagsDropdown
                  placeholder="Select device group"
                  isLoading={deviceGroupsListLoading}
                  invalid={!!errors.deviceGroupIds}
                  options={deviceGroupsDropdownOptions}
                  selectedItems={field.value || []}
                  setSelectedItems={field.onChange}
                  variant="secondary"
                  size="large"
                  disabled={!isEditing}
                />
              )}
            </>
          )}
        />
      </FieldRow>
    </Form>
  );
};

export const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const RemoveBtn = styled(Button)`
  border-radius: 50%;
  font-size: 0;
  padding: 9px;
  min-height: auto;
  margin-right: 12px;

  svg {
    width: 11px;
    height: 11px;
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const TagsWrapper = styled.div`
  margin-bottom: 10px;

  & > span {
    margin-bottom: 5px;
  }
`;

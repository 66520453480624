import { DropdownMenu } from "@asantech/common/react/DropdownMenu";
import { Checkbox, DashboardLayout, Loader } from "components";
import { Camera, Location } from "models/cameras";
import React, { useCallback, useEffect, useState } from "react";
import { useCameras } from "store/Cameras.context";
import styled from "styled-components";
import { CameraTile, LocationTag } from "views/cameras";
import { Feed } from "views/feed";
import { Event } from "../../../backend/src/events/types";
import { noop } from "../common/utils";

export const CamerasView: React.FC = () => {
  const {
    isLoading: devicesLoading,
    camerasById,
    deviceGroupsById,
    camerasSettingsById,
    locations,
    addLocation,
    removeLocation,
    cameraSettingsSavePending,
    detectionStatusByDevice,
  } = useCameras();

  const [expandedCamId, setExpandedCamId] = useState<string | null>(null);

  const [cameras, setCameras] = useState<Camera[]>([]);

  const setExpandedCameraRef = useCallback(
    (expandedCameraEl: HTMLDivElement | null) => {
      if (expandedCameraEl) {
        expandedCameraEl.scrollIntoView({ behavior: "smooth" });
      }
    },
    []
  );

  useEffect(() => {
    const devicesWithDuplicates = locations.map((loc) => loc.cameras).flat();
    const devices = Array.from(new Set(devicesWithDuplicates));

    setCameras(devices);
  }, [locations]);

  const handleCheckboxChange = (shouldAdd: boolean, location: Location) => {
    shouldAdd ? addLocation(location) : removeLocation(location.id);
  };

  const handleTileExpandClick = (id: string) => () =>
    expandedCamId === id ? setExpandedCamId(null) : setExpandedCamId(id);

  const handleAlertBoxClicked = useCallback(
    (alert: Event) => {
      const isCameraVisible = cameras.some(
        (visibleCam) => alert.deviceId === visibleCam.id
      );
      if (!isCameraVisible) {
        const camera = [...camerasById.values()].find(
          (camera) => camera.id === alert.deviceId
        );
        if (!camera) return;
        const deviceGroup = [...deviceGroupsById.values()].find(
          (dgroup) => dgroup.cameras.indexOf(camera) > -1
        );
        if (!deviceGroup) return;
        addLocation(deviceGroup);
      }
      setExpandedCamId(alert.deviceId);
    },
    [addLocation, cameras, camerasById, deviceGroupsById]
  );

  return (
    <DashboardLayout
      barContent={
        <>
          <DropdownMenu placeholder="Select locations">
            <DropdownPlaceholder>
              {devicesLoading && <DropdownLoader size={20} />}
              {[...deviceGroupsById.values()].map((location) => (
                <DropdownMenuItem key={location.id}>
                  <Checkbox
                    label={location.name}
                    onChange={(val) => handleCheckboxChange(val, location)}
                    checked={Boolean(
                      locations.find((el) => el.id === location.id)
                    )}
                  />
                </DropdownMenuItem>
              ))}
            </DropdownPlaceholder>
          </DropdownMenu>
          <TagsWrapper>
            {locations.map(({ id, name }) => (
              <LocationTag
                key={id}
                name={name}
                value={id}
                onButtonClick={removeLocation}
              />
            ))}
          </TagsWrapper>
        </>
      }
      navContent={<SideContentHead>Event log</SideContentHead>}
      sideContent={<Feed handleAlertBoxClicked={handleAlertBoxClicked} />}
    >
      <MasonryWrapper role="main">
        {!cameraSettingsSavePending &&
          cameras.map((camera) => (
            <CameraTileWrapper
              ref={expandedCamId === camera.id ? setExpandedCameraRef : noop}
              expanded={expandedCamId === camera.id}
              key={camera.id}
            >
              <CameraTile
                id={camera.id}
                title={camera.name}
                expanded={expandedCamId === camera.id}
                onExpandClick={handleTileExpandClick(camera.id)}
                camVideoPath={camera.videoPath}
                cameraSettings={camerasSettingsById.get(camera.id)}
                detectedObjects={
                  detectionStatusByDevice[camera.id]?.coordinates
                }
              />
            </CameraTileWrapper>
          ))}
      </MasonryWrapper>
    </DashboardLayout>
  );
};

const MasonryWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 24px;
  grid-auto-flow: dense;
  padding: 16px;
  overflow-y: scroll;
`;

const CameraTileWrapper = styled.div<{ expanded: boolean }>`
  ${({ expanded }) => (!expanded ? "height: 245px;" : "height: 485px;")}
  ${({ expanded }) =>
    expanded &&
    `
    grid-column-end: span 2;
    grid-row-end: span 2;
  `}
`;
const SideContentHead = styled.div`
  font-size: 16px;
  font-weight: bold;
  color: var(--text-primary);
  padding: 16px;
  padding-bottom: 8px;
`;

const DropdownMenuItem = styled.div`
  border: 0;
  box-shadow: none;
  background: transparent;
  font-size: 14px;
  font-weight: 400;
  color: var(--text-secondary);
  padding: 11px 16px 10px;
  width: 100%;
  text-align: left;
  border-bottom: 1px solid var(--secondary);

  &:hover {
    cursor: pointer;
    background: #2d3035;
  }

  &:last-child {
    border-bottom: 0;
  }
`;

const TagsWrapper = styled.div`
  padding-left: 24px;
`;

const DropdownLoader = styled(Loader)`
  margin: 30px;
`;

const DropdownPlaceholder = styled.div`
  min-height: 80px;
`;

import { PeriodByWeekDay } from "../devices/types";

export function padZero(value: string | number) {
  return ("0" + value).slice(-2);
}

export function extractUTCTimeFromDate(date: Date) {
  return [date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds()]
    .map(padZero)
    .join(":");
}

/**
 * Checks if passed date is inside period.
 * If current time is equal to period.to it is considered to be outside of given period
 */
export function isDateInsidePeriod(date: Date, period: PeriodByWeekDay) {
  const timeFromDate = extractUTCTimeFromDate(date);
  const dayMatches = date.getUTCDay() === period.dayOfWeek;

  const timeMatches =
    period.from &&
    timeFromDate >= period.from &&
    (period.to === null || timeFromDate < period.to) &&
    period.to != timeFromDate;

  return dayMatches && timeMatches;
}

import React, { ReactNode } from "react";

import styled from "styled-components";

interface Props {
  disabled?: boolean;
  children?: ReactNode;
}

export const AlertTooltip: React.FC<Props> = ({ children }) => {
  return <AlertTooltipWrapper>{children}</AlertTooltipWrapper>;
};

const AlertTooltipWrapper = styled.div`
  &:first-letter {
    text-transform: uppercase;
  }

  display: inline-block;
  text-overflow: ellipsis;
  overflow: hidden;
  background: var(--alert-tooltip-bg);
  color: var(--alert-tooltip);
  padding: 6px 21px;
  border-radius: 3em;
  letter-spacing: 0;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  min-height: 34px;
  cursor: auto;
`;

import { Camera } from "models/cameras";
import { useParams } from "react-router-dom";
import { useCameras } from "store/Cameras.context";
import { CameraContainer } from "views/camera/CameraContainer";
import { CameraSettings } from "../../../backend/src/devices/types";

export const CameraView = () => {
  const { deviceId } = useParams<{ deviceId: string }>();
  const { camerasById, camerasSettingsById, isLoading, isLoadingSettings } =
    useCameras();

  const cameraData = camerasById.get(deviceId) as Camera;
  const cameraSettings = camerasSettingsById.get(deviceId) as CameraSettings;

  if (!cameraData || isLoading || isLoadingSettings) return null;

  return (
    <CameraContainer cameraData={cameraData} cameraSettings={cameraSettings} />
  );
};

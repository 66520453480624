import { padZero } from "../../../../backend/src/utils/timeUtils";
import { groupByKey } from "../../common/utils";
import { PeriodByWeekDay } from "./types";

export const MINUTES_IN_DAY = 24 * 60;
const MINUTES_IN_WEEK = 7 * MINUTES_IN_DAY;

export const mergeContiguousPeriods = (periods: PeriodByWeekDay[]) => {
  const result = [];
  const selectedPeriodsByDay = groupByKey(sortAndUnique(periods), "dayOfWeek");
  for (const [, periodsInDay] of Object.entries(selectedPeriodsByDay)) {
    let merged: PeriodByWeekDay | null = null;

    for (const period of periodsInDay) {
      if (merged === null) {
        merged = period;
        continue;
      }
      const isRightAfterPrevious = period.from === merged.to;
      if (isRightAfterPrevious) {
        merged.to = period.to;
      } else {
        result.push(merged);
        merged = period;
      }
    }
    if (merged != null) {
      result.push(merged);
    }
  }

  return result;
};

const timeStringToMinutes = (time = "") => {
  const [hours, mins] = time.split(":").map((val) => parseInt(val, 10));
  return (hours || 0) * 60 + (mins || 0);
};

export const getSplitPeriodsFnForCalendarResolution =
  (allCalendarPeriods: PeriodByWeekDay[]) => (periods: PeriodByWeekDay[]) => {
    let result: PeriodByWeekDay[] = [];
    const scheduleResolutionForCurrent =
      MINUTES_IN_WEEK / allCalendarPeriods.length;

    const selectedPeriodsByDay = groupByKey(
      sortAndUnique(periods),
      "dayOfWeek"
    );
    const calendarPeriodsByDay = groupByKey(
      sortAndUnique(allCalendarPeriods),
      "dayOfWeek"
    );
    for (const [day, periodsInDay] of Object.entries(selectedPeriodsByDay)) {
      const calendarPeriods = calendarPeriodsByDay[day];
      if (!calendarPeriods)
        throw new Error(
          "Can't split periods, day for selected periods is not in all periods."
        );

      for (const periodInDay of periodsInDay) {
        result = result.concat(
          calendarPeriods
            .map((period) => {
              const fromMins = timeStringToMinutes(period.from);
              const fromInDayMins = timeStringToMinutes(periodInDay.from);
              const toMins = timeStringToMinutes(period.to || "24:00");
              const toInDayMins = timeStringToMinutes(
                periodInDay.to || "24:00"
              );

              if (fromInDayMins < toMins && toInDayMins > fromMins) {
                const minutesWithinPeriod =
                  Math.min(toInDayMins, toMins) -
                  Math.max(fromInDayMins, fromMins);

                if (minutesWithinPeriod < scheduleResolutionForCurrent) {
                  period.fill =
                    minutesWithinPeriod / scheduleResolutionForCurrent;
                  period.position = fromInDayMins > fromMins ? "bottom" : "top";
                }
                return period;
              }

              return null;
            })
            .filter((p): p is PeriodByWeekDay => !!p)
        );
      }
    }

    return result;
  };

function sortAndUnique(periods: PeriodByWeekDay[]) {
  const strings = [...periods]
    .sort((a, b) => (a.from > b.from ? 1 : -1))
    .map((value) => JSON.stringify(value));
  return Array.from(new Set(strings)).map((value) =>
    JSON.parse(value)
  ) as PeriodByWeekDay[];
}

export const splitTime = (time: string): [string, string] => {
  const [hours, minutes] = time.split(":");
  if (hours === undefined || minutes === undefined)
    throw new Error(`Invalid period, expected hh:mm, got ${time}`);
  return [hours, minutes];
};

export const dayMinutesToLocalPeriodTime = (
  utcMinutes: number,
  utcDayOfWeek: number
): [string, number] => {
  const durationMinutes = utcDayOfWeek * MINUTES_IN_DAY + utcMinutes;
  const zoneMinutes = durationMinutes + new Date().getTimezoneOffset();
  const hours = padZero(Math.floor((zoneMinutes % MINUTES_IN_DAY) / 60));
  const minutes = padZero(Math.floor(zoneMinutes % 60));
  let dayOfWeek = Math.floor(zoneMinutes / MINUTES_IN_DAY);

  if (dayOfWeek > 6) dayOfWeek = 0;
  if (dayOfWeek < 0) dayOfWeek = 6;

  return [[hours, minutes, "00"].join(":"), dayOfWeek];
};
